import React from "react";

interface IAuthContext {
  balance: number;
  setBalance: any;
}

export const AuthContext = React.createContext<IAuthContext>({
  balance: 0,
  setBalance: null,
});
