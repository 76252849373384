import { useContext, useState } from "react";
import "./styles.scss";
import Crash from "./Crash";
import CrashBet from "../../components/UI/BetWindow/CrashBet";
import { useRecoilState } from "recoil";
import { SocketContext } from "../../context/socket/socket";
import { crashPlayState } from "../../store/atoms/crashPlayerState";
import CrashPlayers from "./components/CrashPlayers/CrashPlayers";
import Balance from "../../components/UI/Balance/Balance";
import ReactGA from "react-ga4";
import clickaudio from "../../components/shared/sounds/Click.mp3";

export default function CrashGame() {
  const { depositCrash, cancelNextRoundBet, cashoutCrash, startAutoBet, stopAutoBet } =
    useContext(SocketContext);
  const [crashPlay, setCrashPlay] = useRecoilState(crashPlayState);
  const [betValue, setBetValue] = useState(100);
  const [autoCashout, setAutoCashout] = useState(0);
  const [autoBetSettings, setAutoBetSettings] = useState({
    betValue: 100,
    autoCashout: 1.5,
    betNumber: 10,
    takeProfit: 100,
    stopLoss: 100,
  });

  const makeBet = async (nextRound = false) => {
    try {
      setCrashPlay((prev) => ({ ...prev, crashGameStarted: true }));
      if (nextRound) {
        setCrashPlay((prev) => ({ ...prev, bettingNextRound: true }));
      }
      const sound = new Audio(clickaudio);
      sound.volume = 0.5;
      sound.currentTime = 0;
      sound.play().catch(function (error) {
        console.log("Chrome cannot play sound without user interaction first");
      });
      ReactGA.event({ category: "Make Bet", action: `make_bet_crash` });
      depositCrash(betValue, autoCashout);
    } catch (e) {
      setCrashPlay((prev) => ({
        ...prev,
        crashGameStarted: false,
        bettingNextRound: false,
      }));
    }
  };

  const cancelBet = async () => {
    setCrashPlay((prev) => ({
      ...prev,
      crashGameStarted: false,
      bettingNextRound: false,
    }));
    cancelNextRoundBet();
  };

  const startAutoBetMode = () => {
    startAutoBet(autoBetSettings);
  };

  const stopAutoBetMode = () => {
    stopAutoBet();
  };

  const cashOut = async () => {
    cashoutCrash();
  };

  return (
    <div className="crash-container">
      <div className="crash-main">
        <div className="crash-left">
        <CrashBet
            bet={betValue}
            setBet={setBetValue}
            makeBet={makeBet}
            cancelBet={cancelBet}
            cashOut={cashOut}
            autoCashout={autoCashout}
            setAutoCashout={setAutoCashout}
            crashGameStarted={crashPlay.crashGameStarted}
            autoBetSettings={autoBetSettings}
            setAutoBetSettings={setAutoBetSettings}
            autoBet={crashPlay.autoBet}
            startAutoBet={startAutoBetMode}
            stopAutoBet={stopAutoBetMode}
          />
          {crashPlay.mode === "manual" ? <CrashPlayers /> : <></>}
          <Balance />
        </div>
        <Crash />
      </div>
    </div>
  );
}
