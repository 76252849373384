import { useContext, useEffect, useLayoutEffect, useState } from "react";
import "./styles.scss";
import Animation from "./animation";
import spriteFile from "./images/sprites.png";
import { useRecoilState } from "recoil";
import { crashState } from "../../store/atoms/crashState";
import DimensionsContext from "../../context/dimensions/dimensions";

export default function Crash() {
  const [crashInfo, setCrashInfo] = useRecoilState(crashState);
  const [game, setGame] = useState<Game>();
  const { x, y } = useContext(DimensionsContext);

  const Align = {
    LEFT: "LEFT",
    CENTER: "CENTER",
    RIGHT: "RIGHT",
  };
  const Stage = {
    LOADED: "LOADED",
    READY: "READY",
    STARTED: "STARTED",
    CRASHED: "CRASHED",
  };

  const elementId = "targetContainer";

  const updateSettings = () => {
    const element = document.getElementById(elementId);
    let tempSettings: any = {
      htmlWidth: element?.clientWidth!,
      htmlHeight: element?.clientHeight!,
      width: element?.clientWidth! * window.devicePixelRatio,
      height: element?.clientHeight! * window.devicePixelRatio,
      ratio: window.devicePixelRatio,
      deviderDiff: x > 900 ? 1 / 10 : 1 / 20,
      func: function (t: number) {
        return 1.0024 * Math.pow(1.0718, t);
      },
    };
    return tempSettings;
  };

  useEffect(() => {
    const tempSettings = updateSettings();
    game?.resize(tempSettings);
  }, [x, y]);

  const image = new Image();

  useLayoutEffect(() => {
    image.onload = function () {
      const canvasSettings = updateSettings();
      const newGame = new Game(document.getElementById(elementId), canvasSettings);
      setGame(newGame);
    };

    image.src = spriteFile;
  }, []);

  useEffect(() => {
    if (game && !game.loaded && crashInfo.tail.length) {
      game.init({
        tail: crashInfo.tail,
      });
    }
    if (crashInfo && game) {
      if (crashInfo.crashed) {
        game.crash({
          round: crashInfo.round,
          crashed: crashInfo.crashTime,
          value: crashInfo.crashValue,
          nextInit: {
            tail: crashInfo.tail,
            nextRound: crashInfo.nextRoundStart,
            currentTime: crashInfo,
          },
        });
        setCrashInfo((prev) => ({ ...prev, crashed: false }));
      }
      if (crashInfo.betting) {
        game.readyStage({ nextRound: crashInfo.nextRoundStart });
        setCrashInfo((prev) => ({ ...prev, betting: false }));
      }
      if (crashInfo.game) {
        game.start({
          round: crashInfo.round,
          started: Date.now() - crashInfo.timeElapsed * 1000,
        });
        setCrashInfo((prev) => ({ ...prev, game: false }));
      }
    }
  }, [crashInfo]);

  const sprites = {
    white: {
      0: { width: 93, height: 108, x: 0, y: 220, padding: 32 },
      1: { width: 84, height: 108, x: 93, y: 220, padding: 32 },
      2: { width: 93, height: 108, x: 177, y: 220, padding: 32 },
      3: { width: 93, height: 108, x: 270, y: 220, padding: 32 },
      4: { width: 95, height: 108, x: 363, y: 220, padding: 32 },
      5: { width: 92, height: 108, x: 458, y: 220, padding: 32 },
      6: { width: 93, height: 108, x: 550, y: 220, padding: 32 },
      7: { width: 94, height: 108, x: 643, y: 220, padding: 32 },
      8: { width: 93, height: 108, x: 737, y: 220, padding: 32 },
      9: { width: 93, height: 108, x: 830, y: 220, padding: 32 },
      dot: { width: 72, height: 108, x: 1020, y: 220, padding: 40 },
      x: { width: 97, height: 108, x: 923, y: 220, padding: 40 },
      width: 105,
    },

    green: {
      0: { width: 93, height: 108, x: 0, y: 328, padding: 32 },
      1: { width: 84, height: 108, x: 93, y: 328, padding: 32 },
      2: { width: 93, height: 108, x: 177, y: 328, padding: 32 },
      3: { width: 93, height: 108, x: 270, y: 328, padding: 32 },
      4: { width: 95, height: 108, x: 363, y: 328, padding: 32 },
      5: { width: 92, height: 108, x: 458, y: 328, padding: 32 },
      6: { width: 93, height: 108, x: 550, y: 328, padding: 32 },
      7: { width: 94, height: 108, x: 643, y: 328, padding: 32 },
      8: { width: 93, height: 108, x: 737, y: 328, padding: 32 },
      9: { width: 93, height: 108, x: 830, y: 328, padding: 32 },
      dot: { width: 72, height: 108, x: 1020, y: 328, padding: 40 },
      x: { width: 97, height: 108, x: 923, y: 328, padding: 40 },
      width: 105,
    },

    red: {
      0: { width: 93, height: 108, x: 0, y: 436, padding: 32 },
      1: { width: 84, height: 108, x: 93, y: 436, padding: 32 },
      2: { width: 93, height: 108, x: 177, y: 436, padding: 32 },
      3: { width: 93, height: 108, x: 270, y: 436, padding: 32 },
      4: { width: 95, height: 108, x: 363, y: 436, padding: 32 },
      5: { width: 92, height: 108, x: 458, y: 436, padding: 32 },
      6: { width: 93, height: 108, x: 550, y: 436, padding: 32 },
      7: { width: 94, height: 108, x: 643, y: 436, padding: 32 },
      8: { width: 93, height: 108, x: 737, y: 436, padding: 32 },
      9: { width: 93, height: 108, x: 830, y: 436, padding: 32 },
      dot: { width: 72, height: 108, x: 1020, y: 436, padding: 40 },
      x: { width: 97, height: 108, x: 923, y: 436, padding: 40 },
      width: 105,
    },

    rocket: { width: 172, height: 172, x: 0, y: 0 },
    explose: { width: 172, height: 172, x: 172, y: 0 },

    flameframe1: { width: 104, height: 48, x: 23, y: 172 },
    flameframe2: { width: 104, height: 48, x: 152, y: 172 },
    flameframe3: { width: 104, height: 48, x: 281, y: 172 },
    flameframe4: { width: 104, height: 48, x: 408, y: 172 },
  };

  //The Class of beautiful rates
  class CustomNumber {
    canvasSettings: any;
    num: number;
    fontSet: any;
    align: any;
    width: number;
    x: number;
    y: number;
    arrLetters: any[];
    color: string;
    scale: number;

    constructor(num: number, fontSet = sprites.white, canvasSettings: any) {
      this.canvasSettings = canvasSettings;
      this.num = Math.trunc(num * 100) / 100;
      this.fontSet = fontSet;
      this.align = Align.LEFT;
      this.width = 0;
      this.x = 0;
      this.y = 0;
      this.arrLetters = [];
      this.color = "white";
      this.scale = 1;

      this.combine();
    }

    setNumber(num: number) {
      this.num = Math.trunc(num * 100) / 100;
      this.combine();
    }

    setAlign(align = Align.LEFT) {
      this.align = align;
    }

    setFont(fontSet: any) {
      this.fontSet = fontSet;
      this.combine();
    }

    combine() {
      this.width = 0;
      this.arrLetters = [];

      const integer = String(Math.abs(Math.trunc(this.num)));
      let fraction = String(
        Math.abs(Math.trunc(Math.round((this.num % 1) * 100)))
      );

      if (fraction.length < 2) fraction = "0" + fraction;

      for (let i = 0; i < integer.length; i++) {
        const char = integer.charAt(i);
        const charArr = this.fontSet[char];
        this.width += this.fontSet.width - charArr.padding * 2;
        this.arrLetters.push(charArr);
      }

      this.width += this.fontSet.width - this.fontSet.dot.padding * 2;
      this.arrLetters.push(this.fontSet.dot);

      for (let i = 0; i < fraction.length; i++) {
        const char = fraction.charAt(i);
        const charArr = this.fontSet[char];
        this.width += this.fontSet.width - charArr.padding * 2;
        this.arrLetters.push(charArr);
      }

      // Add 0s to the tail
      for (let i = 0; i < 2 - fraction.length; i++) {
        const charArr = this.fontSet[0];
        this.width += this.fontSet.width - charArr.padding * 2;
        this.arrLetters.push(charArr);
      }

      this.width += this.fontSet.width - this.fontSet.x.padding;
      this.arrLetters.push(this.fontSet.x);
    }

    drawX(ctx: any) {
      let left = 0;

      switch (this.align) {
        case Align.RIGHT:
          left =
            this.width * -1 +
            this.arrLetters[0].padding -
            this.arrLetters[this.arrLetters.length - 1].padding;
          break;
        case Align.CENTER:
          left = (this.width / 2) * -1 - this.arrLetters[0].padding / 2;
          break;
        case Align.LEFT:
          left = -this.arrLetters[0].padding;
          break;
      }

      for (let i = 0; i < this.arrLetters.length; i++) {
        const letter = this.arrLetters[i];
        ctx.drawImage(
          image,
          letter.x,
          letter.y,
          letter.width,
          letter.height,
          this.x + left * this.canvasSettings.ratio,
          this.y - (this.arrLetters[0].height / 2) * this.canvasSettings.ratio,
          letter.width * this.canvasSettings.ratio,
          letter.height * this.canvasSettings.ratio
        );
        left += this.fontSet.width - letter.padding * 2;
      }
    }
    drawInt(ctx: any) {
      let left = 0;
      let width = this.width - this.fontSet.width;
      switch (this.align) {
        case Align.RIGHT:
          left =
            width * -1 +
            this.arrLetters[0].padding -
            this.arrLetters[this.arrLetters.length - 2].padding;
          break;
        case Align.CENTER:
          left = (width / 2) * -1 - this.arrLetters[0].padding;
          break;
        case Align.LEFT:
          left = -this.arrLetters[0].padding;
          break;
      }

      for (let i = 0; i < this.arrLetters.length - 1; i++) {
        const letter = this.arrLetters[i];
        ctx.drawImage(
          image,
          letter.x,
          letter.y,
          letter.width,
          letter.height,
          this.x + left * this.canvasSettings.ratio,
          this.y - (this.arrLetters[0].height / 2) * this.canvasSettings.ratio,
          letter.width * this.canvasSettings.ratio,
          letter.height * this.canvasSettings.ratio
        );
        left += this.fontSet.width - letter.padding * 2;
      }
    }
  }

  //The Class of tail items
  class TailItem {
    element: any;
    constructor(value: any) {
      this.element = document.createElement("div");
      if (value > 2) {
        this.element.classList.add("hilighted");
      }
      value = Math.trunc(value * 100) / 100;
      let str = String(value);
      if (str.length < 2) str += ".00x";
      else if (str.length < 4) str += "0x";
      else str += "x";
      this.element.innerText = str;
    }
  }

  //The Main class
  class Game {
    element: any;
    stage: any;
    c: any;
    ctx: any;
    taillist: any;
    tail: any;
    round: any;
    nextRoundStart: any;
    roundFactor: any;
    rocketCount: number;
    rocketNextFrame: number;
    ready: any;
    crashed: any;
    loaded: boolean;
    canvasSettings: any;

    constructor(element: any, canvasSettings: any) {
      this.canvasSettings = canvasSettings;
      this.element = element;

      //Stage
      this.stage = Stage.LOADED;

      //Canvas
      this.c = document.createElement("canvas");
      this.c.width = canvasSettings.width;
      this.c.height = canvasSettings.height;
      this.c.style.width = canvasSettings.htmlWidth + "px";
      this.c.style.height = canvasSettings.htmlHeight + "px";
      this.ctx = this.c.getContext("2d");
      this.ctx.imageSmoothingEnabled = true;
      element.appendChild(this.c);

      //Tail
      this.taillist = document.createElement("div");
      this.taillist.id = "taillist";
      this.element.appendChild(this.taillist);
      this.tail = [];

      //Round
      this.round = 0;
      this.nextRoundStart = Infinity;
      this.roundFactor = 0;

      //Rocket
      this.rocketCount = 1; /// Range 1...4
      this.rocketNextFrame = performance.now() + 200;

      //Numbers
      this.ready = new CustomNumber(0, sprites.white, canvasSettings);
      this.ready.x = 0;
      this.ready.y = 0;
      this.ready.setAlign(Align.CENTER);

      this.crashed = new CustomNumber(0, sprites.green, canvasSettings);
      this.crashed.x = 0;
      this.crashed.y = 0;
      this.crashed.setAlign(Align.CENTER);

      this.loaded = false;

      //Start main loop
      this.loop(performance.now());
    }

    init(obj: any) {
      this.loaded = true;
      for (let i = 0; i < obj.tail.length; i++) {
        const item = obj.tail[i];
        let newElem = new TailItem(item);
        this.tail.push(newElem);
        this.taillist.appendChild(newElem.element);
        setTimeout(function () {
          newElem.element.classList.add("visibled");
        }, (i + 1) * 50);
      }
      this.ready.setFont(sprites.white);
      // this.stage = Stage.READY;
      // this.nextRoundStart = obj.nextRound;
    }

    start(obj: any) {
      this.round = obj.started;
      this.ready.setFont(sprites.green);
      this.stage = Stage.STARTED;
    }

    crash(obj: any) {
      this.crashed.setNumber(obj.value);
      ///Set color of number on crash
      //this.crashed.setFont(obj.value > bet ? sprites.green : sprites.red);
      this.crashed.setFont(sprites.red);

      let newElem = new TailItem(obj.value);
      this.tail.push(newElem);
      this.taillist.appendChild(newElem.element);

      this.tail[0].element.classList.remove("visibled");
      this.tail[0].element.classList.add("deleted");

      newElem.element.classList.add("visibled");

      let that = this;
      setTimeout(function () {
        that.tail[0].element.remove();
        that.tail.shift();
      }, 300);
      this.stage = Stage.CRASHED;
      this.nextRoundStart = obj.nextInit.nextRound;

      that = this;

      //Start animation scale
      that.crashed.scale = 1;
      new Animation(
        300, //duration
        (v: any) => {
          //callback
          that.crashed.scale = 1 - v;
        },
        1500, //delay
        Animation.TimeFn.easeInOutCubic, //timing function
        () => {
          //calback in final
          that.crashed.scale = 0;
        }
      );

      //Go to ready. Make this in socket event Init
      // setTimeout(() => {
      //   that.stage = Stage.READY;
      // }, 2000);
    }

    readyStage(options: any) {
      this.stage = Stage.READY;
      this.nextRoundStart = options.nextRound;
      this.ready.setFont(sprites.white);
    }

    resize(updatedSettings: any) {
      this.canvasSettings = updatedSettings;
      this.c.width = updatedSettings.width;
      this.c.height = updatedSettings.height;
      this.c.style.width = updatedSettings.htmlWidth + "px";
      this.c.style.height = updatedSettings.htmlHeight + "px";
    }

    clearCanvas() {
      this.ctx.clearRect(0, 0, this.c.width, this.c.height);
    }

    loop(t: any) {
      this.ready.scale = this.canvasSettings.htmlHeight / 500;
      this.crashed.scale = this.canvasSettings.htmlHeight / 500;
      this.clearCanvas();

      let factor = (Date.now() - this.round) * 1;
      let pointT = 0;
      let pointX = 0;
      let X;
      let s;
      let devider;
      let deviderDiff = 25;

      switch (this.stage) {
        case Stage.READY:
          let backtime = Math.trunc(
            (this.nextRoundStart - Date.now()) / 1000 + 1
          );

          ///Draw 1.00x and countdown
          this.ready.setNumber(1);
          this.ctx.save();
          this.ctx.translate(
            this.canvasSettings.width / 2,
            this.canvasSettings.height / 2 - 54 * this.canvasSettings.ratio
          );
          this.ctx.scale(this.ready.scale, this.ready.scale);
          this.ready.drawX(this.ctx);
          this.ctx.textAlign = "center";
          this.ctx.font =
            "bold " + 12 * this.canvasSettings.ratio + "px 'Jost'";
          this.ctx.fillStyle = "white";
          this.ctx.fillText(
            "STARTING IN " + backtime,
            0,
            50 * this.canvasSettings.ratio
          );
          this.ctx.restore();
          break;
        case Stage.STARTED:
          this.roundFactor = factor;

          pointT =
            (this.canvasSettings.width - 74 * this.canvasSettings.ratio) /
            (factor < 3000 ? 3000 : factor) /
            1.2;
          pointX =
            (this.canvasSettings.height - 66 * this.canvasSettings.ratio) /
            this.canvasSettings.func(factor / 1000) /
            1.2;

          this.ctx.save();
          this.ctx.translate(
            //To intersection position of axises'
            this.canvasSettings.ratio * 54,
            this.canvasSettings.height - this.canvasSettings.ratio * 47
          );

          ///Draw seconds Axis
          this.ctx.textAlign = "left";
          this.ctx.fillStyle = "red";
          devider = factor < 3000 ? 3 : factor / 1000;
          s =
            (this.canvasSettings.width - 74 * this.canvasSettings.ratio) /
            devider;

          this.ctx.fillStyle = "#F8F8FF";
          this.ctx.font = `normal ${
            14 * this.canvasSettings.ratio
          }px 'Jost'`;

          if (devider < 10) {
            deviderDiff = 1;
          } else if (devider < 50) {
            deviderDiff = 5;
          }

          for (let i = 0; i < devider; i += deviderDiff) {
            this.ctx.fillText(`${i}s`, s * i, 20 * this.canvasSettings.ratio);
          }

          ///Draw result Axis
          devider =
            this.canvasSettings.func(factor / 1000) < 3
              ? 3
              : this.canvasSettings.func(factor / 1000);
          X =
            (this.canvasSettings.height - 66 * this.canvasSettings.ratio) /
            devider;
          this.ctx.textAlign = "right";
          deviderDiff = 1024;
          if (devider < 5) {
            deviderDiff = 0.5;
          } else if (devider < 10) {
            deviderDiff = 1;
          } else if (devider < 20) {
            deviderDiff = 2;
          } else if (devider < 40) {
            deviderDiff = 4;
          } else if (devider < 80) {
            deviderDiff = 8;
          } else if (devider < 160) {
            deviderDiff = 16;
          } else if (devider < 320) {
            deviderDiff = 32;
          } else if (devider < 640) {
            deviderDiff = 64;
          } else if (devider < 1280) {
            deviderDiff = 128;
          } else if (devider < 2560) {
            deviderDiff = 256;
          } else if (devider < 5120) {
            deviderDiff = 512;
          }
          for (let i = 0; i < devider; i += deviderDiff) {
            this.ctx.fillText(
              `${i + 1}x`,
              -5 * this.canvasSettings.ratio,
              -X * i
            );
          }

          ///Draw shadow chart
          this.ctx.beginPath();
          this.ctx.moveTo(0, this.canvasSettings.func(0));
          this.ctx.strokeStyle = "rgba(114, 241, 56, 0.1)";
          this.ctx.lineCap = "round";
          this.ctx.setLineDash([
            5 * this.canvasSettings.ratio,
            5 * this.canvasSettings.ratio,
          ]);
          this.ctx.lineWidth = 2 * this.canvasSettings.ratio;
          let max = factor < 3000 ? 3600 : factor * 1.2;
          for (let f = 0; f < max; f += 10) {
            if (
              pointT * f <
              this.canvasSettings.width - 74 * this.canvasSettings.ratio
            ) {
              this.ctx.lineTo(
                pointT * f,
                (-1 * this.canvasSettings.func(f / 1000) + 1.0024) * pointX
              );
            }
          }
          this.ctx.stroke();

          ///Draw life chart
          this.ctx.beginPath();
          this.ctx.setLineDash([]);
          this.ctx.moveTo(0, this.canvasSettings.func(0));
          this.ctx.strokeStyle = "rgba(114, 241, 56, 0.4)";
          for (let f = 0; f < factor; f += 10) {
            this.ctx.lineTo(
              pointT * f,
              (-1 * this.canvasSettings.func(f / 1000) + 1.0024) * pointX
            );
          }
          this.ctx.stroke();

          ///Draw Vehicle
          let x = pointT * factor;
          let tan =
            ((((1.0718 * factor * 1.0024) / 1000) * Math.log(1.0718) * pointT) /
              pointX) *
            1000; ///The devirative of function in settings.func ->

          let alpha =
            (-Math.atan((tan * factor) / pointX / (factor / 1000)) * factor) /
            (factor * (this.canvasSettings.width / this.canvasSettings.height)); ///bull shit

          this.ctx.translate(
            x,
            (-1 * this.canvasSettings.func(factor / 1000) + 1.0024) * pointX
          );
          this.ctx.rotate(alpha);

          //Draw Flame
          if (this.rocketNextFrame < performance.now()) {
            if (this.rocketCount >= 4) {
              this.rocketCount = 1;
              this.rocketNextFrame = performance.now() + 50;
            } else {
              this.rocketCount++;
              this.rocketNextFrame = performance.now() + 50;
            }
          }

          //@ts-ignore
          let sprite = sprites[`flameframe${this.rocketCount}`];

          this.ctx.scale(
            this.canvasSettings.htmlHeight / 500 > 1.2 ? 1.2 : this.canvasSettings.htmlHeight / 500,
            this.canvasSettings.htmlHeight / 500 > 1.2 ? 1.2 : this.canvasSettings.htmlHeight / 500
          );
          this.ctx.drawImage(
            image,
            sprite.x,
            sprite.y,
            sprite.width,
            sprite.height,
            -sprite.width * 1.4 * this.canvasSettings.ratio,
            (-sprite.height / 2) * this.canvasSettings.ratio,
            sprite.width * this.canvasSettings.ratio,
            sprite.height * this.canvasSettings.ratio
          );

          this.ctx.scale(
            this.canvasSettings.htmlHeight / 500 > 1.2 ? 1.2 : this.canvasSettings.htmlHeight / 500,
            this.canvasSettings.htmlHeight / 500 > 1.2 ? 1.2 : this.canvasSettings.htmlHeight / 500
          );
          //Draw Rocket
          this.ctx.drawImage(
            image,
            sprites.rocket.x,
            sprites.rocket.y,
            sprites.rocket.width,
            sprites.rocket.height,
            (-sprites.rocket.width / 2) * this.canvasSettings.ratio,
            (-sprites.rocket.height / 2) * this.canvasSettings.ratio,
            sprites.rocket.width * this.canvasSettings.ratio,
            sprites.rocket.height * this.canvasSettings.ratio
          );

          this.ctx.restore();

          ///Draw factor numbers
          this.ctx.save();
          this.ctx.translate(
            //to center of canvas
            this.canvasSettings.width / 2,
            this.canvasSettings.height / 2 - 54 * this.canvasSettings.ratio
          );
          this.ready.setNumber(this.canvasSettings.func(factor / 1000));

          setCrashInfo((prev) => ({
            ...prev,
            multiplier: Number(
              this.canvasSettings.func(factor / 1000).toFixed(2)
            ),
          }));

          this.ctx.scale(this.ready.scale, this.ready.scale);
          this.ready.drawX(this.ctx);
          this.ctx.restore();

          break;
        case Stage.CRASHED:
          pointT =
            (this.canvasSettings.width - 74 * this.canvasSettings.ratio) /
            (this.roundFactor < 3000 ? 3000 : this.roundFactor) /
            1.2;
          pointX =
            (this.canvasSettings.height - 66 * this.canvasSettings.ratio) /
            this.canvasSettings.func(this.roundFactor / 1000) /
            1.2;

          this.ctx.save();
          this.ctx.translate(
            //To intersection position of axises'
            this.canvasSettings.ratio * 54,
            this.canvasSettings.height - this.canvasSettings.ratio * 47
          );

          ///Draw seconds Axis (reiterative)
          this.ctx.textAlign = "left";
          this.ctx.fillStyle = "red";
          devider = this.roundFactor < 3000 ? 3 : this.roundFactor / 1000;
          s =
            (this.canvasSettings.width - 74 * this.canvasSettings.ratio) /
            devider;

          this.ctx.fillStyle = "#F8F8FF";
          this.ctx.font = `normal ${14 * this.canvasSettings.ratio}px 'Jost'`;

          if (devider < 10) {
            deviderDiff = 1;
          } else if (devider < 50) {
            deviderDiff = 5;
          }

          for (let i = 0; i < devider; i += deviderDiff) {
            this.ctx.fillText(`${i}s`, s * i, 20 * this.canvasSettings.ratio);
          }

          ///Draw result Axis (reiterative)
          devider =
            this.canvasSettings.func(this.roundFactor / 1000) < 3
              ? 3
              : this.canvasSettings.func(this.roundFactor / 1000);
          X =
            (this.canvasSettings.height - 66 * this.canvasSettings.ratio) /
            devider;
          this.ctx.textAlign = "right";

          deviderDiff = 1024;
          if (devider < 5) {
            deviderDiff = 0.5;
          } else if (devider < 10) {
            deviderDiff = 1;
          } else if (devider < 20) {
            deviderDiff = 2;
          } else if (devider < 40) {
            deviderDiff = 4;
          } else if (devider < 80) {
            deviderDiff = 8;
          } else if (devider < 160) {
            deviderDiff = 16;
          } else if (devider < 320) {
            deviderDiff = 32;
          } else if (devider < 640) {
            deviderDiff = 64;
          } else if (devider < 1280) {
            deviderDiff = 128;
          } else if (devider < 2560) {
            deviderDiff = 256;
          } else if (devider < 5120) {
            deviderDiff = 512;
          }

          for (let i = 0; i < devider; i += deviderDiff) {
            this.ctx.fillText(
              `${i + 1}x`,
              -5 * this.canvasSettings.ratio,
              -X * i
            );
          }

          this.ctx.save();
          this.ctx.translate(
            //To center of explose
            pointT * this.roundFactor,
            (-1 * this.canvasSettings.func(this.roundFactor / 1000) + 1.0024) *
              pointX
          );

          this.ctx.scale(
            this.canvasSettings.htmlHeight / 500 > 1.2 ? 1.2 : this.canvasSettings.htmlHeight / 500,
            this.canvasSettings.htmlHeight / 500 > 1.2 ? 1.2 : this.canvasSettings.htmlHeight / 500
          );
          ///Draw explose
          this.ctx.drawImage(
            image,
            sprites.explose.x,
            sprites.explose.y,
            sprites.explose.width,
            sprites.explose.height,
            (-sprites.explose.width / 2) * this.canvasSettings.ratio,
            (-sprites.explose.height / 2) * this.canvasSettings.ratio,
            sprites.explose.width * this.canvasSettings.ratio,
            sprites.explose.height * this.canvasSettings.ratio
          );
          this.ctx.restore();
          this.ctx.restore();

          ///Draw Result
          this.ctx.save();
          this.ctx.translate(
            //To center of canvas
            this.canvasSettings.width / 2,
            this.canvasSettings.height / 2 - 54 * this.canvasSettings.ratio
          );
          this.ctx.scale(this.crashed.scale, this.crashed.scale);
          this.crashed.drawX(this.ctx);
          this.ctx.restore();
          break;
        default:
          break;
      }

      Animation.playQueue();
      window.requestAnimationFrame(() => this.loop(performance.now()));
    }
  }

  return <div id="targetContainer"></div>;
}