import React, { useContext } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { useRoutes } from "./hooks/useRoutes";
import { AuthContext } from "./context/auth/auth";
import Snackbar from "./components/shared/snackbar/Snackbar";

function App() {
  // const { isAuth } = useContext(AuthContext);
  const routes = useRoutes(true);

  return (
    <>
      <BrowserRouter>{routes}</BrowserRouter>
      <Snackbar />
    </>
  );
}

export default App;
