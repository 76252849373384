import { useContext, useEffect } from "react";
import { AuthContext } from "../context/auth/auth";
import { useHttp } from "./useHttps";
import { useSnackbar } from "./useSnackbar";
import { useParams } from "react-router-dom";

export default function useAuth() {
  const { request } = useHttp();
  const { setBalance } = useContext(AuthContext);
  const { notify } = useSnackbar();
  const {key} = useParams();

  useEffect(() => {
    if (key) {
      localStorage.setItem("token", key)
    }
    if (localStorage.token) {
      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getUser() {
    try {
      const data = await request("/api/auth/balance", "GET", null, {
        "x-auth-token": localStorage.token ? localStorage.token : "demo",
      });
      const { balance } = data;

      setBalance(balance);
    } catch (e) {
      notify("Failed to authorize", "error");
      localStorage.removeItem("token");
    }
  }

  return { getUser };
}
