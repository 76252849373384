import { useState } from "react";
import { AuthContext } from "./auth";

export default function AuthProvider({ children }: any) {
  const [balance, setBalance] = useState<number>(0);

  return (
    <AuthContext.Provider value={{ balance, setBalance }}>
      {children}
    </AuthContext.Provider>
  );
}
