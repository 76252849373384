import { atom } from "recoil";


export interface IPlayer {
  isPlaying: boolean;
  bet: number;
  cashoutMultiplier: number;
  isLose: boolean;
}

interface ICrashPlay {
  crashGameStarted: boolean;
  cashedOut: boolean;
  autoBet: boolean;
  mode: string;
  bettingNextRound: boolean;
  player: IPlayer;
}

export const crashPlayState = atom<ICrashPlay>({
  key: "crashPlay",
  default: {
    crashGameStarted: false,
    cashedOut: false,
    autoBet: false,
    mode: "manual",
    bettingNextRound: false,
    player: {
      isPlaying: false,
      bet: 0,
      cashoutMultiplier: 0,
      isLose: false,
    },
  },
});
