import { atom } from "recoil";

export interface IActivePlayer {
  user: any;
  bet: number;
  cashoutMultiplier: number;
  isLose: boolean;
}

export interface ICrash {
  round: number;
  crashed: boolean;
  game: boolean;
  betting: boolean;
  crashValue: number;
  crashTime: any;
  timeElapsed: number;
  nextRoundStart: number;
  tail: any[];
  multiplier: number;
  activePlayers: IActivePlayer[]
}

export const crashState = atom<ICrash>({
  key: "crash",
  default: {
    round: 0,
    crashed: false,
    game: false,
    betting: false,
    crashValue: Infinity,
    crashTime: 0,
    timeElapsed: 0,
    nextRoundStart: 0,
    tail: [],
    multiplier: 0,
    activePlayers: []
  },
});
