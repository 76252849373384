// multiplierImages
import multiplier019 from "../images/multipliers/019.svg";
import multiplier028 from "../images/multipliers/028.svg";
import multiplier037 from "../images/multipliers/037.svg";
import multiplier047 from "../images/multipliers/047.svg";
import multiplier057 from "../images/multipliers/057.svg";
import multiplier065 from "../images/multipliers/065.svg";
import multiplier084 from "../images/multipliers/084.svg";
import multiplier094 from "../images/multipliers/094.svg";
import multiplier103 from "../images/multipliers/103.svg";
import multiplier112 from "../images/multipliers/112.svg";
import multiplier122 from "../images/multipliers/122.svg";
import multiplier131 from "../images/multipliers/131.svg";
import multiplier14 from "../images/multipliers/14.svg";
import multiplier15 from "../images/multipliers/15.svg";
import multiplier177 from "../images/multipliers/177.svg";
import multiplier187 from "../images/multipliers/187.svg";
import multiplier196 from "../images/multipliers/196.svg";
import multiplier281 from "../images/multipliers/281.svg";
import multiplier374 from "../images/multipliers/374.svg";
import multiplier468 from "../images/multipliers/468.svg";
import multiplier524 from "../images/multipliers/524.svg";
import multiplier655 from "../images/multipliers/655.svg";
import multiplier664 from "../images/multipliers/664.svg";
import multiplier758 from "../images/multipliers/758.svg";
import multiplier833 from "../images/multipliers/833.svg";
import multiplier842 from "../images/multipliers/842.svg";
import multiplier936 from "../images/multipliers/936.svg";
import multiplier1030 from "../images/multipliers/1030.svg";
import multiplier1220 from "../images/multipliers/1220.svg";
import multiplier140 from "../images/multipliers/140.svg";
import multiplier150 from "../images/multipliers/150.svg";
import multiplier168 from "../images/multipliers/168.svg";
import multiplier206 from "../images/multipliers/206.svg";
import multiplier224 from "../images/multipliers/224.svg";
import multiplier243 from "../images/multipliers/243.svg";
import multiplier271 from "../images/multipliers/271.svg";
import multiplier309 from "../images/multipliers/309.svg";
import multiplier384 from "../images/multipliers/384.svg";
import multiplier5240 from "../images/multipliers/5240.svg";
import multiplier543 from "../images/multipliers/543.svg";
import multiplier711 from "../images/multipliers/711.svg";
import multiplier1029 from "../images/multipliers/1029.svg";
import multiplier1216 from "../images/multipliers/1216.svg";
import multiplier159 from "../images/multipliers/159.svg";
import multiplier3929 from "../images/multipliers/3929.svg";
import multiplier9355 from "../images/multipliers/9355.svg";

import { ReactComponent as svgmultiplier019 } from "../images/multipliers/019.svg";
import { ReactComponent as svgmultiplier028 } from "../images/multipliers/028.svg";
import { ReactComponent as svgmultiplier037 } from "../images/multipliers/037.svg";
import { ReactComponent as svgmultiplier047 } from "../images/multipliers/047.svg";
import { ReactComponent as svgmultiplier057 } from "../images/multipliers/057.svg";
import { ReactComponent as svgmultiplier065 } from "../images/multipliers/065.svg";
import { ReactComponent as svgmultiplier084 } from "../images/multipliers/084.svg";
import { ReactComponent as svgmultiplier094 } from "../images/multipliers/094.svg";
import { ReactComponent as svgmultiplier103 } from "../images/multipliers/103.svg";
import { ReactComponent as svgmultiplier112 } from "../images/multipliers/112.svg";
import { ReactComponent as svgmultiplier122 } from "../images/multipliers/122.svg";
import { ReactComponent as svgmultiplier131 } from "../images/multipliers/131.svg";
import { ReactComponent as svgmultiplier14 } from "../images/multipliers/14.svg";
import { ReactComponent as svgmultiplier15 } from "../images/multipliers/15.svg";
import { ReactComponent as svgmultiplier177 } from "../images/multipliers/177.svg";
import { ReactComponent as svgmultiplier187 } from "../images/multipliers/187.svg";
import { ReactComponent as svgmultiplier196 } from "../images/multipliers/196.svg";
import { ReactComponent as svgmultiplier281 } from "../images/multipliers/281.svg";
import { ReactComponent as svgmultiplier374 } from "../images/multipliers/374.svg";
import { ReactComponent as svgmultiplier468 } from "../images/multipliers/468.svg";
import { ReactComponent as svgmultiplier524 } from "../images/multipliers/524.svg";
import { ReactComponent as svgmultiplier655 } from "../images/multipliers/655.svg";
import { ReactComponent as svgmultiplier664 } from "../images/multipliers/664.svg";
import { ReactComponent as svgmultiplier758 } from "../images/multipliers/758.svg";
import { ReactComponent as svgmultiplier833 } from "../images/multipliers/833.svg";
import { ReactComponent as svgmultiplier842 } from "../images/multipliers/842.svg";
import { ReactComponent as svgmultiplier936 } from "../images/multipliers/936.svg";
import { ReactComponent as svgmultiplier1030 } from "../images/multipliers/1030.svg";
import { ReactComponent as svgmultiplier1220 } from "../images/multipliers/1220.svg";
import { ReactComponent as svgmultiplier140 } from "../images/multipliers/140.svg";
import { ReactComponent as svgmultiplier150 } from "../images/multipliers/150.svg";
import { ReactComponent as svgmultiplier168 } from "../images/multipliers/168.svg";
import { ReactComponent as svgmultiplier206 } from "../images/multipliers/206.svg";
import { ReactComponent as svgmultiplier224 } from "../images/multipliers/224.svg";
import { ReactComponent as svgmultiplier243 } from "../images/multipliers/243.svg";
import { ReactComponent as svgmultiplier271 } from "../images/multipliers/271.svg";
import { ReactComponent as svgmultiplier309 } from "../images/multipliers/309.svg";
import { ReactComponent as svgmultiplier384 } from "../images/multipliers/384.svg";
import { ReactComponent as svgmultiplier5240 } from "../images/multipliers/5240.svg";
import { ReactComponent as svgmultiplier543 } from "../images/multipliers/543.svg";
import { ReactComponent as svgmultiplier711 } from "../images/multipliers/711.svg";
import { ReactComponent as svgmultiplier1029 } from "../images/multipliers/1029.svg";
import { ReactComponent as svgmultiplier1216 } from "../images/multipliers/1216.svg";
import { ReactComponent as svgmultiplier159 } from "../images/multipliers/159.svg";
import { ReactComponent as svgmultiplier3929 } from "../images/multipliers/3929.svg";
import { ReactComponent as svgmultiplier9355 } from "../images/multipliers/9355.svg";

import lowmultiplieraudio from "../sounds/LowMultiplier.mp3";
import mediummultiplieraudio from "../sounds/MediumMultiplier.mp3";
import highmultiplieraudio from "../sounds/HighMultiplier.mp3";

import { Howl } from "howler";
import { LinesType, LinesTypes, Mode, MultiplierType } from "../types";

const HighAudio = new Howl({
  src: [highmultiplieraudio],
  volume: 0.2,
  html5: true,
});
const MediumAudio = new Howl({
  src: [mediummultiplieraudio],
  volume: 0.2,
  html5: true,
});
const LowAudio = new Howl({
  src: [lowmultiplieraudio],
  volume: 0.2,
  html5: true,
});

export const getMultiplierSound = (multiplier: number) => {
  if (multiplier >= 25) {
    return HighAudio;
  } else if (multiplier >= 5) {
    return MediumAudio;
  } else {
    return LowAudio;
  }
};

const multipliers = {
  0.19: {
    label: "block-0.19",
    img: multiplier019,
    svg: svgmultiplier019,
  },
  0.28: {
    label: "block-0.28",
    img: multiplier028,
    svg: svgmultiplier028,
  },
  0.37: {
    label: "block-0.37",
    img: multiplier037,
    svg: svgmultiplier037,
  },
  0.47: {
    label: "block-0.47",
    img: multiplier047,
    svg: svgmultiplier047,
  },
  0.57: {
    label: "block-0.57",
    img: multiplier057,
    svg: svgmultiplier057,
  },
  0.65: {
    label: "block-0.65",
    img: multiplier065,
    svg: svgmultiplier065,
  },
  0.84: {
    label: "block-0.84",
    img: multiplier084,
    svg: svgmultiplier084,
  },
  0.94: {
    label: "block-0.94",
    img: multiplier094,
    svg: svgmultiplier094,
  },
  1.03: {
    label: "block-1.03",
    img: multiplier103,
    svg: svgmultiplier103,
  },
  1.12: {
    label: "block-1.12",
    img: multiplier112,
    svg: svgmultiplier112,
  },
  1.22: {
    label: "block-1.22",
    img: multiplier122,
    svg: svgmultiplier122,
  },
  1.31: {
    label: "block-1.31",
    img: multiplier131,
    svg: svgmultiplier131,
  },
  1.4: {
    label: "block-1.4",
    img: multiplier14,
    svg: svgmultiplier14,
  },
  1.5: {
    label: "block-1.5",
    img: multiplier15,
    svg: svgmultiplier15,
  },
  1.77: {
    label: "block-1.77",
    img: multiplier177,
    svg: svgmultiplier177,
  },
  1.87: {
    label: "block-1.87",
    img: multiplier187,
    svg: svgmultiplier187,
  },
  1.96: {
    label: "block-1.96",
    img: multiplier196,
    svg: svgmultiplier196,
  },
  2.81: {
    label: "block-2.81",
    img: multiplier281,
    svg: svgmultiplier281,
  },
  3.74: {
    label: "block-3.74",
    img: multiplier374,
    svg: svgmultiplier374,
  },
  4.68: {
    label: "block-4.68",
    img: multiplier468,
    svg: svgmultiplier468,
  },
  5.24: {
    label: "block-5.24",
    img: multiplier524,
    svg: svgmultiplier524,
  },
  6.55: {
    label: "block-6.55",
    img: multiplier655,
    svg: svgmultiplier655,
  },
  6.64: {
    label: "block-6.64",
    img: multiplier664,
    svg: svgmultiplier664,
  },
  7.58: {
    label: "block-7.58",
    img: multiplier758,
    svg: svgmultiplier758,
  },
  8.33: {
    label: "block-8.33",
    img: multiplier833,
    svg: svgmultiplier833,
  },
  8.42: {
    label: "block-8.42",
    img: multiplier842,
    svg: svgmultiplier842,
  },
  9.36: {
    label: "block-9.36",
    img: multiplier936,
    svg: svgmultiplier936,
  },
  10.3: {
    label: "block-10.3",
    img: multiplier1030,
    svg: svgmultiplier1030,
  },
  12.2: {
    label: "block-12.2",
    img: multiplier1220,
    svg: svgmultiplier1220,
  },
  14: {
    label: "block-14",
    img: multiplier140,
    svg: svgmultiplier140,
  },
  15: {
    label: "block-15",
    img: multiplier150,
    svg: svgmultiplier150,
  },
  16.8: {
    label: "block-16.8",
    img: multiplier168,
    svg: svgmultiplier168,
  },
  20.6: {
    label: "block-20.6",
    img: multiplier206,
    svg: svgmultiplier206,
  },
  22.4: {
    label: "block-22.4",
    img: multiplier224,
    svg: svgmultiplier224,
  },
  24.3: {
    label: "block-24.3",
    img: multiplier243,
    svg: svgmultiplier243,
  },
  27.1: {
    label: "block-27.1",
    img: multiplier271,
    svg: svgmultiplier271,
  },
  30.9: {
    label: "block-30.9",
    img: multiplier309,
    svg: svgmultiplier309,
  },
  38.4: {
    label: "block-38.4",
    img: multiplier384,
    svg: svgmultiplier384,
  },
  52.4: {
    label: "block-52.4",
    img: multiplier5240,
    svg: svgmultiplier5240,
  },
  54.3: {
    label: "block-54.3",
    img: multiplier543,
    svg: svgmultiplier543,
  },
  71.1: {
    label: "block-71.1",
    img: multiplier711,
    svg: svgmultiplier711,
  },
  102.9: {
    label: "block-102.9",
    img: multiplier1029,
    svg: svgmultiplier1029,
  },
  121.6: {
    label: "block-121.6",
    img: multiplier1216,
    svg: svgmultiplier1216,
  },
  159: {
    label: "block-159",
    img: multiplier159,
    svg: svgmultiplier159,
  },
  392.9: {
    label: "block-392.9",
    img: multiplier3929,
    svg: svgmultiplier3929,
  },
  935.5: {
    label: "block-935.5",
    img: multiplier9355,
    svg: svgmultiplier9355,
  },
} as const;

export type MultipliersType = keyof typeof multipliers;

export function getMultiplier(value: MultipliersType): MultiplierType {
  return multipliers[value];
}

export const low8Lines = [
  getMultiplier(5.24),
  getMultiplier(1.96),
  getMultiplier(1.03),
  getMultiplier(0.94),
  getMultiplier(0.47),
  getMultiplier(0.94),
  getMultiplier(1.03),
  getMultiplier(1.96),
  getMultiplier(5.24),
];

export const low10Lines = [
  getMultiplier(8.33),
  getMultiplier(2.81),
  getMultiplier(1.31),
  getMultiplier(1.03),
  getMultiplier(0.94),
  getMultiplier(0.47),
  getMultiplier(0.94),
  getMultiplier(1.03),
  getMultiplier(1.31),
  getMultiplier(2.81),
  getMultiplier(8.33),
];
export const low12Lines = [
  getMultiplier(9.36),
  getMultiplier(2.81),
  getMultiplier(1.5),
  getMultiplier(1.31),
  getMultiplier(1.03),
  getMultiplier(0.94),
  getMultiplier(0.47),
  getMultiplier(0.94),
  getMultiplier(1.03),
  getMultiplier(1.31),
  getMultiplier(1.5),
  getMultiplier(2.81),
  getMultiplier(9.36),
];
export const low14Lines = [
  getMultiplier(6.64),
  getMultiplier(3.74),
  getMultiplier(1.77),
  getMultiplier(1.31),
  getMultiplier(1.22),
  getMultiplier(1.03),
  getMultiplier(0.94),
  getMultiplier(0.47),
  getMultiplier(0.94),
  getMultiplier(1.03),
  getMultiplier(1.22),
  getMultiplier(1.31),
  getMultiplier(1.77),
  getMultiplier(3.74),
  getMultiplier(6.64),
];
export const low16Lines = [
  getMultiplier(15.0),
  getMultiplier(8.42),
  getMultiplier(1.87),
  getMultiplier(1.31),
  getMultiplier(1.31),
  getMultiplier(1.12),
  getMultiplier(1.03),
  getMultiplier(0.94),
  getMultiplier(0.47),
  getMultiplier(0.94),
  getMultiplier(1.03),
  getMultiplier(1.12),
  getMultiplier(1.31),
  getMultiplier(1.31),
  getMultiplier(1.87),
  getMultiplier(8.42),
  getMultiplier(15.0),
];

export const medium8Lines = [
  getMultiplier(12.2),
  getMultiplier(2.81),
  getMultiplier(1.22),
  getMultiplier(0.65),
  getMultiplier(0.37),
  getMultiplier(0.65),
  getMultiplier(1.22),
  getMultiplier(2.81),
  getMultiplier(12.2),
];

export const medium10Lines = [
  getMultiplier(20.6),
  getMultiplier(4.68),
  getMultiplier(1.87),
  getMultiplier(1.31),
  getMultiplier(0.57),
  getMultiplier(0.37),
  getMultiplier(0.57),
  getMultiplier(1.31),
  getMultiplier(1.87),
  getMultiplier(4.68),
  getMultiplier(20.6),
];
export const medium12Lines = [
  getMultiplier(30.9),
  getMultiplier(10.3),
  getMultiplier(3.74),
  getMultiplier(1.87),
  getMultiplier(1.03),
  getMultiplier(0.57),
  getMultiplier(0.28),
  getMultiplier(0.57),
  getMultiplier(1.03),
  getMultiplier(1.87),
  getMultiplier(3.74),
  getMultiplier(10.3),
  getMultiplier(30.9),
];
export const medium14Lines = [
  getMultiplier(54.3),
  getMultiplier(14.0),
  getMultiplier(6.55),
  getMultiplier(3.74),
  getMultiplier(1.77),
  getMultiplier(0.94),
  getMultiplier(0.47),
  getMultiplier(0.19),
  getMultiplier(0.47),
  getMultiplier(0.94),
  getMultiplier(1.77),
  getMultiplier(3.74),
  getMultiplier(6.55),
  getMultiplier(14.0),
  getMultiplier(54.3),
];
export const medium16Lines = [
  getMultiplier(102.9),
  getMultiplier(38.4),
  getMultiplier(9.36),
  getMultiplier(4.68),
  getMultiplier(2.81),
  getMultiplier(1.4),
  getMultiplier(0.94),
  getMultiplier(0.47),
  getMultiplier(0.28),
  getMultiplier(0.47),
  getMultiplier(0.94),
  getMultiplier(1.4),
  getMultiplier(2.81),
  getMultiplier(4.68),
  getMultiplier(9.36),
  getMultiplier(38.4),
  getMultiplier(102.9),
];

export const high8Lines = [
  getMultiplier(27.1),
  getMultiplier(3.74),
  getMultiplier(1.4),
  getMultiplier(0.28),
  getMultiplier(0.19),
  getMultiplier(0.28),
  getMultiplier(1.4),
  getMultiplier(3.74),
  getMultiplier(27.1),
];

export const high10Lines = [
  getMultiplier(71.1),
  getMultiplier(9.36),
  getMultiplier(2.81),
  getMultiplier(0.84),
  getMultiplier(0.28),
  getMultiplier(0.19),
  getMultiplier(0.28),
  getMultiplier(0.84),
  getMultiplier(2.81),
  getMultiplier(9.36),
  getMultiplier(71.1),
];
export const high12Lines = [
  getMultiplier(159.0),
  getMultiplier(22.4),
  getMultiplier(7.58),
  getMultiplier(1.87),
  getMultiplier(0.65),
  getMultiplier(0.19),
  getMultiplier(0.19),
  getMultiplier(0.19),
  getMultiplier(0.65),
  getMultiplier(1.87),
  getMultiplier(7.58),
  getMultiplier(22.4),
  getMultiplier(159.0),
];
export const high14Lines = [
  getMultiplier(392.9),
  getMultiplier(52.4),
  getMultiplier(16.8),
  getMultiplier(4.68),
  getMultiplier(1.77),
  getMultiplier(0.28),
  getMultiplier(0.19),
  getMultiplier(0.19),
  getMultiplier(0.19),
  getMultiplier(0.28),
  getMultiplier(1.77),
  getMultiplier(4.68),
  getMultiplier(16.8),
  getMultiplier(52.4),
  getMultiplier(392.9),
];
export const high16Lines = [
  getMultiplier(935.5),
  getMultiplier(121.6),
  getMultiplier(24.3),
  getMultiplier(8.42),
  getMultiplier(3.74),
  getMultiplier(1.87),
  getMultiplier(0.19),
  getMultiplier(0.19),
  getMultiplier(0.19),
  getMultiplier(0.19),
  getMultiplier(0.19),
  getMultiplier(1.87),
  getMultiplier(3.74),
  getMultiplier(8.42),
  getMultiplier(24.3),
  getMultiplier(121.6),
  getMultiplier(935.5),
];

export const multiplyBlocksByLinesQnt = {
  low8: low8Lines,
  medium8: medium8Lines,
  high8: high8Lines,
  low10: low10Lines,
  medium10: medium10Lines,
  high10: high10Lines,
  low12: low12Lines,
  medium12: medium12Lines,
  high12: high12Lines,
  low14: low14Lines,
  medium14: medium14Lines,
  high14: high14Lines,
  low16: low16Lines,
  medium16: medium16Lines,
  high16: high16Lines,
};

export function getMultiplierByLinesQnt(
  value: LinesTypes,
  mode: Mode
): MultiplierType[] {
  const query: LinesType = `${mode}${value}`;
  return multiplyBlocksByLinesQnt[query];
}
