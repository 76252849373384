import { Routes, Route } from "react-router-dom";
import PlinkoPage from "../pages/PlinkoPage";
import TowersPage from "../pages/TowersPage";
import MinesPage from "../pages/MinesPage";
import DicePage from "../pages/DicePage";
import CrashPage from "../pages/CrashPage";

export const useRoutes = (auth: boolean) => {
  return (
    <Routes>
      <Route path="/crash/:key" element={<CrashPage />} />
      <Route path="/plinko/:key" element={<PlinkoPage />} />
      <Route path="/mines/:key" element={<MinesPage />} />
      <Route path="/dice/:key" element={<DicePage />} />
      <Route path="/towers/:key" element={<TowersPage />} />
    </Routes>
  );
};
