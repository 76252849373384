import CrashGame from "../Games/Crash/CrashGame";
import { SocketProvider } from "../context/socket/SocketProvider";

export default function CrashPage() {
  return (
    // <SocketProvider>
      <CrashGame />
    // </SocketProvider>
  );
}
