import { motion } from "framer-motion";

type SVGPropType = {
  position: number;
  isChecked: boolean;
  size: number | string;
};

type OpenedSVGPropType = {
  isMine: boolean;
  size: number | string;
  showAllSquares: boolean;
};

export const SquareSVG = ({ position, isChecked, size = 90 }: SVGPropType) => {
  return position === 1 ||
    position === 8 ||
    position === 15 ||
    position === 22 ? (
    <svg
      width={size}
      height={size}
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M78.966 9H11.034C4.94009 9 0 13.9401 0 20.034V78.966C0 85.0599 4.94009 90 11.034 90H78.966C85.0599 90 90 85.0599 90 78.966V20.034C90 13.9401 85.0599 9 78.966 9Z"
        fill={isChecked ? "#16171F" : "#272939"}
      />
      <path
        d="M90 11.034V69.966C90 72.8924 88.8375 75.6989 86.7682 77.7682C84.6989 79.8375 81.8924 81 78.966 81H67.131L54.09 75.222C54.198 75.411 55.656 77.922 57.438 81H54.081C37.683 73.629 25.785 77.607 19.746 81H11.034C8.1076 81 5.30106 79.8375 3.23178 77.7682C1.16251 75.6989 0 72.8924 0 69.966L0 11.034C0 8.1076 1.16251 5.30106 3.23178 3.23178C5.30106 1.16251 8.1076 0 11.034 0L18.567 0L43.47 7.542L34.344 0H38.844L54.27 5.472L51.669 0H78.966C81.8924 0 84.6989 1.16251 86.7682 3.23178C88.8375 5.30106 90 8.1076 90 11.034Z"
        fill={isChecked ? "#16171F" : "#313344"}
      />
      <path
        d="M83.8702 31.383C83.7442 34.704 83.8702 38.088 83.8702 41.445H83.7892C83.7892 44.802 83.7892 48.159 83.7892 51.516C83.7892 52.668 83.7892 53.784 83.7352 54.855L77.2462 48.213L82.3672 66.843C81.0712 72.243 78.8482 75.303 75.5902 76.032C72.1936 76.6924 68.684 76.463 65.4022 75.366C52.8071 71.5118 39.4914 70.6217 26.4952 72.765C20.1952 73.791 13.9492 74.196 7.71224 71.244C5.81324 70.344 5.67824 69.3 6.07424 64.296C6.53324 58.635 6.70424 52.785 6.86624 46.98C6.91424 45.318 6.95024 43.653 6.97424 41.985L17.1622 26.271L7.19924 30.42C7.27124 26.613 7.36124 22.815 7.53224 19.044C8.03624 7.60504 10.0162 3.66304 14.2732 4.64404C15.6682 4.95904 17.0452 5.90404 18.4402 6.32704C21.9322 7.37104 25.4062 8.85604 28.9072 9.12604C38.1502 9.85504 47.4112 10.143 56.6632 10.359C58.9079 10.4418 61.1336 9.91962 63.1072 8.84704C67.7062 6.30004 72.2422 7.78504 76.7872 9.83704C77.878 10.4739 78.8176 11.3398 79.5412 12.375C80.7573 13.9355 81.6922 15.6958 82.3042 17.577L79.0912 36.72L83.8972 27.45C83.9332 28.683 83.9242 29.997 83.8702 31.383Z"
        fill={isChecked ? "#16171F" : "#3D4055"}
      />
    </svg>
  ) : position === 2 || position === 9 || position === 16 || position === 23 ? (
    <svg
      width={size}
      height={size}
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 20.4929V78.9929C0.00714941 81.9146 1.17281 84.7142 3.24131 86.7776C5.30982 88.8411 8.11227 89.9999 11.034 89.9999H78.966C81.8924 89.9999 84.6989 88.8374 86.7682 86.7681C88.8375 84.6988 90 81.8923 90 78.9659V20.4659L0 20.4929Z"
        fill={isChecked ? "#16171F" : "#272939"}
      />
      <path
        d="M90.0001 11.034V69.966C89.9986 72.6849 88.9941 75.3078 87.179 77.3321C85.3639 79.3565 82.8657 80.6401 80.1631 80.937L77.5171 72.495L50.6071 81H45.9001L52.5871 73.413C52.5871 73.413 35.4871 77.967 23.8861 81H11.0251C9.57648 81 8.1421 80.7145 6.8039 80.1599C5.4657 79.6053 4.24991 78.7924 3.22603 77.7677C2.20214 76.743 1.39024 75.5265 0.836707 74.1879C0.283179 72.8492 -0.00111871 71.4146 6.38003e-05 69.966V11.034C-0.00936931 8.25849 1.02755 5.58137 2.9041 3.53635C4.78064 1.49133 7.35897 0.228637 10.1251 0L9.22506 15.201L14.5621 0H78.9661C81.8925 0 84.699 1.16251 86.7683 3.23178C88.8375 5.30106 90.0001 8.1076 90.0001 11.034Z"
        fill={isChecked ? "#16171F" : "#313344"}
      />
      <path
        d="M81.5847 31.563C79.5327 15.039 74.3037 7.362 64.1247 6.876C59.1207 6.633 54.1077 7.263 49.1037 7.308C42.4617 7.371 35.8287 7.308 29.1867 7.2C26.3967 7.2 23.6067 6.759 20.8257 6.894C18.4047 7.002 15.7677 7.713 14.5257 11.466C12.1497 18.81 10.1517 26.514 8.02771 34.092C7.8278 34.8739 7.73396 35.6791 7.74871 36.486C7.69471 44.478 7.40671 52.506 7.74871 60.471C7.7862 62.4906 8.05802 64.4991 8.55871 66.456L22.1307 59.931L15.9567 73.287C18.8391 73.9602 21.8109 74.1669 24.7587 73.899C35.3967 72.738 46.0167 71.199 56.6457 69.75L56.6997 70.812C62.5137 70.056 68.3277 69.381 74.1327 68.517C75.7365 68.2927 77.2722 67.7214 78.6327 66.843C79.761 66.0093 80.535 64.7823 80.8017 63.405C82.2147 52.893 82.9257 42.3 81.5847 31.563ZM51.0567 16.731C50.4422 16.7328 49.841 16.5522 49.3292 16.2121C48.8174 15.872 48.418 15.3877 48.1817 14.8205C47.9453 14.2533 47.8825 13.6287 48.0013 13.0258C48.1201 12.4229 48.4151 11.8688 48.849 11.4336C49.2828 10.9985 49.8361 10.7019 50.4386 10.5813C51.0412 10.4608 51.666 10.5218 52.2339 10.7565C52.8017 10.9912 53.2872 11.3892 53.6288 11.9C53.9704 12.4108 54.1527 13.0115 54.1527 13.626C54.1539 14.0333 54.0747 14.4369 53.9196 14.8136C53.7646 15.1902 53.5367 15.5326 53.2491 15.821C52.9615 16.1095 52.6198 16.3383 52.2436 16.4945C51.8674 16.6506 51.464 16.731 51.0567 16.731ZM65.4567 22.824C64.2748 22.824 63.1194 22.4735 62.1366 21.8169C61.1539 21.1602 60.3879 20.2269 59.9356 19.1349C59.4833 18.0429 59.365 16.8414 59.5955 15.6821C59.8261 14.5229 60.3953 13.4581 61.231 12.6223C62.0668 11.7866 63.1316 11.2174 64.2909 10.9868C65.4501 10.7562 66.6517 10.8746 67.7436 11.3269C68.8356 11.7792 69.7689 12.5452 70.4256 13.5279C71.0822 14.5107 71.4327 15.6661 71.4327 16.848C71.4327 17.6366 71.2767 18.4174 70.9735 19.1454C70.6704 19.8733 70.2262 20.5341 69.6665 21.0897C69.1068 21.6452 68.4427 22.0844 67.7124 22.3821C66.9822 22.6797 66.2003 22.8299 65.4117 22.824H65.4567Z"
        fill={isChecked ? "#16171F" : "#3D4055"}
      />
    </svg>
  ) : position === 3 ||
    position === 10 ||
    position === 17 ||
    position === 24 ? (
    <svg
      width={size}
      height={size}
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.10352e-05 41.4001V78.9661C6.10352e-05 81.8925 1.16257 84.699 3.23184 86.7683C5.30112 88.8376 8.10766 90.0001 11.0341 90.0001H78.9661C81.8925 90.0001 84.699 88.8376 86.7683 86.7683C88.8375 84.699 90.0001 81.8925 90.0001 78.9661V41.4001H6.10352e-05Z"
        fill={isChecked ? "#16171F" : "#272939"}
      />
      <path
        d="M90.0001 11.034V69.966C90.0001 72.8924 88.8376 75.6989 86.7683 77.7682C84.699 79.8375 81.8925 81 78.9661 81H69.3001L60.9301 73.8L61.9201 81H57.5101L57.1771 74.178L43.8751 81H11.0251C9.57648 81 8.1421 80.7145 6.8039 80.1599C5.4657 79.6053 4.24991 78.7924 3.22603 77.7677C2.20215 76.743 1.39024 75.5265 0.836708 74.1879C0.28318 72.8492 -0.00111781 71.4146 6.4709e-05 69.966V38.367C2.56506 29.7 9.00006 9.261 9.22507 8.631L6.4709e-05 21.537V11.034C-0.00111781 9.58542 0.28318 8.1508 0.836708 6.81215C1.39024 5.4735 2.20215 4.25705 3.22603 3.23233C4.24991 2.20761 5.4657 1.39471 6.8039 0.840088C8.1421 0.285468 9.57648 -4.82659e-07 11.0251 0L78.9661 0C81.8925 0 84.699 1.16251 86.7683 3.23178C88.8376 5.30106 90.0001 8.1076 90.0001 11.034Z"
        fill={isChecked ? "#16171F" : "#313344"}
      />
      <path
        d="M77.9853 70.3711C75.3483 74.7001 72.9003 74.6461 70.2003 70.3081C69.8403 69.7321 69.2283 69.3091 68.8683 69.6691C66.0243 72.5131 63.0813 72.1891 60.1293 71.8111C54.7492 71.0585 49.3096 70.8204 43.8843 71.1001L38.7993 55.1071L32.8593 72.9001C32.0853 73.1251 31.3203 73.368 30.5463 73.6291C27.8463 74.5291 25.2093 74.3401 22.6893 71.5501C20.3223 68.9221 17.9733 69.0931 15.5613 71.217C14.1548 72.5772 12.5494 73.7153 10.8003 74.592C8.01928 75.7891 6.62428 73.0711 5.70628 66.4921C3.76228 52.3171 5.20227 39.6001 8.04628 27.2521C10.7463 15.5521 14.7513 10.3411 20.2323 9.93605C22.6443 9.75605 25.0563 9.45005 27.5223 9.03605L61.4703 26.2261L38.8983 6.97505L40.3023 6.71405L60.3993 16.1191L64.8993 9.18005C66.4411 9.81656 67.9105 10.6161 69.2823 11.5651C71.4333 13.0501 73.7823 12.9781 76.0143 13.5631C77.1393 13.8601 78.2823 13.8961 79.3983 14.2651C82.1973 15.1651 83.8353 19.2961 84.6003 25.965C86.1933 40.2481 82.9713 62.1001 77.9853 70.3711Z"
        fill={isChecked ? "#16171F" : "#3D4055"}
      />
    </svg>
  ) : position === 4 ||
    position === 11 ||
    position === 18 ||
    position === 25 ? (
    <svg
      width={size}
      height={size}
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.10352e-05 21.186V78.966C6.10352e-05 81.8924 1.16257 84.699 3.23184 86.7682C5.30112 88.8375 8.10766 90 11.0341 90H78.9661C81.8925 90 84.699 88.8375 86.7683 86.7682C88.8375 84.699 90.0001 81.8924 90.0001 78.966V21.186H6.10352e-05Z"
        fill={isChecked ? "#16171F" : "#272939"}
      />
      <path
        d="M90.0001 11.034V69.966C90.0001 72.8924 88.8376 75.6989 86.7683 77.7682C84.6991 79.8375 81.8925 81 78.9661 81H50.7961L52.6411 75.789L43.2631 81H40.5001L45.2071 74.475L30.1411 81H16.2001L8.36112 73.116L8.10012 80.595C5.77249 79.9545 3.71943 78.5677 2.25616 76.6476C0.792897 74.7275 0.000317086 72.3801 0.000117683 69.966V11.034C-0.00676052 8.88704 0.612864 6.78471 1.78305 4.98467C2.95324 3.18463 4.62315 1.76508 6.58812 0.9L24.7051 6.777L19.8001 0H22.2031L36.5041 4.626L33.1291 0H78.9661C81.8925 0 84.6991 1.16251 86.7683 3.23178C88.8376 5.30106 90.0001 8.1076 90.0001 11.034Z"
        fill={isChecked ? "#16171F" : "#313344"}
      />
      <path
        d="M81.5306 18.9001C81.3326 17.8261 81.1076 16.8361 80.8556 15.9301C79.9489 17.5785 78.4447 18.8167 76.6528 19.3898C74.8608 19.9628 72.9173 19.8272 71.2223 19.0109C69.5273 18.1945 68.2097 16.7594 67.5406 15.0011C66.8716 13.2427 66.902 11.2947 67.6256 9.55806C66.2534 9.54526 64.8944 9.2893 63.6116 8.80206C59.1745 6.87333 54.2887 6.21586 49.4996 6.90306C43.7306 7.80306 37.9436 7.94706 32.1656 8.70306C28.5845 9.11447 25.0481 9.84947 21.5996 10.8991C15.8396 12.6991 11.2496 19.7011 8.22561 32.9131C6.02061 42.6601 6.38061 47.9161 9.80961 53.0101C10.1606 52.2991 10.3766 51.8761 10.3766 51.8761L14.3276 36.2611C14.3276 36.2611 14.8406 65.7001 15.0026 68.4001L15.1376 68.4451C16.4156 67.1311 27.6566 57.4381 27.6566 57.4381C27.6566 57.4381 26.8286 67.3381 25.7306 71.2171C31.3885 72.3768 37.1705 72.8153 42.9386 72.5221C50.7686 72.0721 58.6166 73.5211 66.4556 74.3851C68.5346 74.5831 70.1996 72.9001 71.6396 69.3001C73.0796 65.7001 74.6366 63.7561 76.6346 63.8281C78.9476 63.9091 80.5766 60.8491 81.5036 55.5301C83.6829 43.4181 83.692 31.0152 81.5306 18.9001ZM65.0696 28.6021C64.2123 28.602 63.3742 28.3475 62.6618 27.8706C61.9493 27.3937 61.3945 26.716 61.0676 25.9234C60.7408 25.1308 60.6566 24.259 60.8259 23.4185C60.9951 22.5781 61.4101 21.8068 62.0182 21.2024C62.6264 20.5981 63.4002 20.188 64.2417 20.024C65.0833 19.86 65.9545 19.9496 66.745 20.2814C67.5356 20.6132 68.2098 21.1722 68.6822 21.8877C69.1546 22.6031 69.404 23.4427 69.3986 24.3001C69.3915 25.4435 68.9322 26.5377 68.1212 27.3437C67.3101 28.1497 66.2131 28.6021 65.0696 28.6021Z"
        fill={isChecked ? "#16171F" : "#3D4055"}
      />
    </svg>
  ) : position === 5 || position === 12 || position === 19 ? (
    <svg
      width={size}
      height={size}
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M78.9661 9H11.0341C4.94021 9 0.00012207 13.9401 0.00012207 20.034V78.966C0.00012207 85.0599 4.94021 90 11.0341 90H78.9661C85.06 90 90.0001 85.0599 90.0001 78.966V20.034C90.0001 13.9401 85.06 9 78.9661 9Z"
        fill={isChecked ? "#16171F" : "#272939"}
      />
      <path
        d="M90.0001 11.034V69.966C90.0001 72.8924 88.8376 75.6989 86.7683 77.7682C84.6991 79.8375 81.8925 81 78.9661 81H59.0581L13.8151 73.107L29.8531 81H11.0341C8.10772 81 5.30118 79.8375 3.23191 77.7682C1.16263 75.6989 0.00012207 72.8924 0.00012207 69.966L0.00012207 11.034C0.00012207 8.1076 1.16263 5.30106 3.23191 3.23178C5.30118 1.16251 8.10772 0 11.0341 0L13.0591 0C18.0811 3.834 28.4311 9.063 40.2841 0H78.9841C81.9074 0.00476881 84.7093 1.16938 86.7747 3.23814C88.8401 5.3069 90.0001 8.11072 90.0001 11.034Z"
        fill={isChecked ? "#16171F" : "#313344"}
      />
      <path
        d="M82.6377 61.704C82.4127 70.173 80.5767 74.304 77.1837 74.799C76.7097 74.9518 76.1997 74.9518 75.7257 74.799C68.2107 71.1 60.6237 73.071 53.0997 72.765C42.815 72.41 32.5787 71.1877 22.4997 69.111C24.2187 64.611 27.7737 54.378 27.8997 53.964C27.4677 54.18 16.1097 60.012 11.3847 62.658C10.849 61.2312 10.4218 59.766 10.1067 58.275C8.23753 50.5616 7.45596 42.6248 7.78467 34.695C7.97367 29.7 7.19968 24.3 6.78568 19.152C6.11068 11.214 6.33568 9.42297 9.48567 7.72197C11.5017 6.65097 13.7787 6.09297 15.7317 7.31697C23.0217 11.898 30.2757 10.773 37.6557 9.11697C45.0357 7.46097 52.4877 7.46997 59.8947 7.19997C62.5017 7.05009 65.117 7.14349 67.7067 7.47897C69.5067 13.239 73.7367 26.757 73.9167 26.757L78.4167 13.257L79.2357 14.013L75.9867 43.047L82.6647 29.934C82.6647 34.146 82.6647 38.358 82.6647 42.534C82.6737 48.951 82.7997 55.35 82.6377 61.704Z"
        fill={isChecked ? "#16171F" : "#3D4055"}
      />
    </svg>
  ) : position === 6 || position === 13 || position === 20 ? (
    <svg
      width={size}
      height={size}
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M87.354 21.8791H0V78.9661C0 81.8925 1.16251 84.6991 3.23178 86.7684C5.30106 88.8376 8.1076 90.0001 11.034 90.0001H78.966C81.8924 90.0001 84.6989 88.8376 86.7682 86.7684C88.8375 84.6991 90 81.8925 90 78.9661V21.4381C89.1495 21.7345 88.2547 21.8837 87.354 21.8791Z"
        fill={isChecked ? "#16171F" : "#272939"}
      />
      <path
        d="M90 11.034V69.975C90 71.4236 89.7145 72.858 89.1599 74.1962C88.6053 75.5344 87.7924 76.7502 86.7677 77.774C85.743 78.7979 84.5265 79.6098 83.1879 80.1634C81.8492 80.7169 80.4146 81.0012 78.966 81H63.54L63.846 75.789L37.098 81H10.998C8.07867 80.9929 5.28135 79.8281 3.2196 77.7613C1.15784 75.6945 -8.75433e-06 72.8943 0 69.975L0 11.034C0 8.1076 1.16251 5.30106 3.23178 3.23178C5.30106 1.16251 8.1076 0 11.034 0L41.463 0L52.587 4.329L56.079 0H78.147L76.941 10.305L87.363 3.879C89.0706 5.87115 90.0064 8.41013 90 11.034Z"
        fill={isChecked ? "#16171F" : "#313344"}
      />
      <path
        d="M84.3845 67.653C82.9355 53.811 81.0005 40.041 81.9545 25.956C82.0796 23.9052 82.0616 21.8482 81.9005 19.8C81.7661 17.3128 81.2532 14.8606 80.3795 12.528L73.4675 19.206L73.7375 6.08399L73.3595 5.97599L71.1005 15.507L67.3925 5.39999C66.2167 5.41489 65.0459 5.55671 63.9005 5.82299C55.1885 7.92899 46.5755 7.55099 37.8905 5.49899C28.8905 3.39299 20.0615 4.73399 11.3225 7.84799C7.44349 9.23399 4.77049 12.402 4.90549 17.748C5.22049 30.348 5.45449 42.948 6.21949 55.467L13.4195 40.914L7.9655 69.3C9.7655 74.034 13.3655 76.041 18.4865 75.897C22.9865 75.771 27.3875 74.709 31.8425 74.313C49.0055 72.774 66.1865 70.713 83.8445 76.383C84.0695 73.044 84.6545 70.272 84.3845 67.653ZM12.1415 19.719C11.4131 19.719 10.701 19.5029 10.0955 19.098C9.48998 18.6931 9.01818 18.1177 8.73984 17.4446C8.4615 16.7714 8.38913 16.0308 8.5319 15.3165C8.67466 14.6023 9.02614 13.9464 9.54183 13.4319C10.0575 12.9175 10.7143 12.5676 11.4289 12.4266C12.1435 12.2856 12.8839 12.3598 13.5564 12.6398C14.2289 12.9198 14.8031 13.393 15.2065 13.9995C15.6099 14.606 15.8243 15.3186 15.8225 16.047C15.8225 16.53 15.7272 17.0082 15.5421 17.4543C15.357 17.9004 15.0857 18.3056 14.7438 18.6467C14.4019 18.9878 13.996 19.2581 13.5495 19.4421C13.1029 19.6261 12.6245 19.7202 12.1415 19.719ZM29.1515 20.187C28.0152 20.1887 26.9039 19.8539 25.9577 19.2246C25.0116 18.5953 24.2731 17.6999 23.8354 16.6513C23.3977 15.6027 23.2804 14.4479 23.4984 13.3327C23.7164 12.2175 24.2599 11.1919 25.0602 10.3853C25.8605 9.57872 26.8819 9.02728 27.9953 8.8006C29.1088 8.57392 30.2644 8.68215 31.3164 9.11165C32.3684 9.54115 33.2696 10.2727 33.9062 11.2138C34.5428 12.155 34.8864 13.2637 34.8935 14.4C34.8982 15.1575 34.7533 15.9085 34.467 16.6098C34.1806 17.3111 33.7586 17.9489 33.225 18.4867C32.6915 19.0244 32.0569 19.4514 31.3579 19.7432C30.6588 20.035 29.909 20.1858 29.1515 20.187Z"
        fill={isChecked ? "#16171F" : "#3D4055"}
      />
    </svg>
  ) : position === 7 || position === 14 || position === 21 ? (
    <svg
      width={size}
      height={size}
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 38.7V78.966C0 81.8924 1.1625 84.6989 3.23178 86.7682C5.30106 88.8375 8.1076 90 11.034 90H78.966C81.8924 90 84.6989 88.8375 86.7682 86.7682C88.8375 84.6989 90 81.8924 90 78.966V38.7H0Z"
        fill={isChecked ? "#16171F" : "#272939"}
      />
      <path
        d="M90 11.034V69.975C90 71.4236 89.7145 72.858 89.1599 74.1962C88.6053 75.5344 87.7924 76.7502 86.7677 77.774C85.743 78.7979 84.5265 79.6098 83.1879 80.1634C81.8492 80.7169 80.4146 81.0012 78.966 81H69.453L67.365 76.707L54.198 81H46.098L43.65 73.494L29.529 81H11.025C9.57685 81.0012 8.14266 80.7168 6.80452 80.1632C5.46637 79.6096 4.25051 78.7975 3.22651 77.7735C2.20251 76.7495 1.39046 75.5336 0.836819 74.1955C0.28318 72.8573 -0.00117994 71.4232 3.67985e-06 69.975V11.034C-0.00117884 9.58542 0.283118 8.15081 0.836647 6.81215C1.39018 5.4735 2.20208 4.25705 3.22597 3.23233C4.24985 2.20761 5.46564 1.39471 6.80384 0.840088C8.14204 0.285468 9.57642 -4.82659e-07 11.025 0L78.966 0C79.182 0 79.398 0 79.605 0L85.059 24.525L89.838 9.144C89.9487 9.76786 90.0029 10.4004 90 11.034Z"
        fill={isChecked ? "#16171F" : "#313344"}
      />
      <path
        d="M83.4212 58.392C83.4212 58.104 83.4212 57.816 83.4212 57.537C82.3412 44.1 81.3782 30.672 80.2262 17.307C79.6592 10.557 77.8682 6.687 75.4382 6.03C72.3885 5.20635 69.2364 4.82446 66.0782 4.896C62.5772 4.986 59.0852 6.354 55.5842 6.219C52.0832 6.084 48.3842 5.778 44.8472 5.472L49.5272 12.141L29.9522 4.5C28.7282 4.5 27.5042 4.5 26.2802 4.5C20.6102 4.608 14.9402 6.876 9.2882 8.775C6.8312 9.603 6.1652 12.483 6.5882 18.423C7.34239 28.9441 7.55567 39.497 7.2272 50.04C7.1462 53.586 7.2272 57.24 7.3892 60.75C7.7492 68.94 9.18919 73.647 12.1322 75.15C13.7402 76.0521 15.6239 76.3308 17.4242 75.933C21.1682 75.033 24.8762 72.639 28.6202 72.333C31.0142 72.153 33.4082 72.072 35.8202 72.045C42.1202 69.345 51.3632 65.529 51.3632 65.529L51.8492 72.162V72.9C58.1492 73.287 64.4492 73.953 70.8122 73.935C74.0023 73.9717 77.1532 73.2303 79.9922 71.775C83.9342 69.696 83.8982 69.174 83.4212 58.392ZM14.0582 19.035C13.4672 19.035 12.8895 18.8598 12.3981 18.5314C11.9068 18.2031 11.5238 17.7364 11.2976 17.1905C11.0715 16.6445 11.0123 16.0437 11.1276 15.4641C11.2429 14.8845 11.5275 14.352 11.9454 13.9342C12.3632 13.5163 12.8957 13.2317 13.4753 13.1164C14.0549 13.0011 14.6557 13.0603 15.2017 13.2864C15.7476 13.5126 16.2143 13.8956 16.5426 14.387C16.871 14.8783 17.0462 15.456 17.0462 16.047C17.0462 16.8395 16.7314 17.5995 16.171 18.1598C15.6107 18.7202 14.8507 19.035 14.0582 19.035ZM71.2712 67.752C70.1334 67.7538 69.0206 67.418 68.0737 66.7872C67.1267 66.1563 66.3882 65.2588 65.9515 64.2081C65.5149 63.1574 65.3997 62.0007 65.6206 60.8846C65.8415 59.7684 66.3885 58.7428 67.1924 57.9376C67.9964 57.1324 69.0211 56.5838 70.1369 56.3612C71.2527 56.1385 72.4095 56.2519 73.4609 56.6869C74.5123 57.1219 75.411 57.859 76.0433 58.805C76.6757 59.7509 77.0132 60.8632 77.0132 62.001C77.0132 63.5247 76.4085 64.9861 75.3319 66.0644C74.2554 67.1427 72.7949 67.7496 71.2712 67.752Z"
        fill={isChecked ? "#16171F" : "#3D4055"}
      />
    </svg>
  ) : null;
};

export const OpenedSquare = ({
  isMine,
  size,
  showAllSquares,
}: OpenedSVGPropType) => {
  return isMine ? (
    <motion.svg
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      width={size}
      height={size}
      viewBox="0 0 99 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <rect width={size} height={size} rx="12" fill="#16171F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.3988 14.4392C60.4134 13.8599 59.1533 14.2037 58.5843 15.207L54.9306 21.6504C53.2944 21.3637 51.6111 21.2142 49.8928 21.2142C33.8804 21.2142 20.8999 34.1948 20.8999 50.2071C20.8999 66.2194 33.8804 79.2 49.8928 79.2C65.9051 79.2 78.8856 66.2194 78.8856 50.2071C78.8856 41.8783 75.3736 34.3697 69.7498 29.0815L72.859 23.5983C73.428 22.5949 73.0903 21.3119 72.1049 20.7326L61.3988 14.4392Z"
          fill="#34384F"
        />
        <g filter="url(#filter0_i_730_2250)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M74.9076 9.9038C79.8107 9.72784 84.16 12.537 86.6363 14.9887C87.4534 15.7977 87.4534 17.1092 86.6363 17.9182C85.8192 18.7271 84.4945 18.7271 83.6774 17.9182C81.6205 15.8817 78.3332 13.9265 75.0591 14.044C72.0663 14.1514 68.2747 16.0456 65.1166 23.4717C64.6682 24.5261 63.4414 25.0209 62.3765 24.577C61.3115 24.133 60.8117 22.9184 61.2601 21.864C64.7971 13.5469 69.7232 10.0899 74.9076 9.9038Z"
            fill="#F82F2F"
          />
        </g>
        <g filter="url(#filter1_i_730_2250)">
          <path
            d="M58.5847 15.2071C59.1537 14.2037 60.4138 13.8599 61.3993 14.4392L72.1053 20.7326C73.0908 21.3119 73.4284 22.5949 72.8595 23.5983L67.6815 32.7298L53.4068 24.3385L58.5847 15.2071Z"
            fill="#34384F"
          />
        </g>
        <path
          d="M78.6112 50.2071C78.6112 66.0676 65.7537 78.9251 49.8932 78.9251C34.0327 78.9251 21.1752 66.0676 21.1752 50.2071C21.1752 34.3466 34.0327 21.4892 49.8932 21.4892C65.7537 21.4892 78.6112 34.3466 78.6112 50.2071Z"
          fill="#34384F"
          stroke="url(#paint0_linear_730_2250)"
          strokeWidth="0.549773"
        />
        <path
          d="M77.4718 49.7741C77.4718 64.8551 65.0411 77.0785 49.7072 77.0785C44.2373 77.0869 38.8878 75.4989 34.3362 72.5156C38.0726 74.1546 42.1189 74.9987 46.2101 74.9926C62.305 74.9926 75.3521 62.163 75.3521 46.3349C75.3597 42.3089 74.5013 38.3267 72.8334 34.65C75.868 39.1285 77.4824 44.3923 77.4718 49.7741Z"
          fill="url(#paint1_linear_730_2250)"
        />
        <path
          opacity="0.8"
          d="M30.218 42.4565C29.5491 47.2999 33.4197 52.7079 33.4197 52.7079L32.7696 57.5674L36.3816 58.7935L37.6003 53.9887L38.9369 54.0526L38.7763 59.603L42.8126 59.9425L43.5882 54.9899C43.5882 54.9899 44.7265 54.9047 44.8872 55.0271C45.0478 55.1496 44.5508 60.5696 44.5508 60.5696L48.1717 60.72L48.867 55.2149C51.7373 54.9686 56.553 51.8558 57.5696 47.5662C58.9916 42.1516 55.2239 34.6867 45.6704 33.2275C36.1168 31.7684 30.8895 37.6197 30.218 42.4565ZM49.3377 41.6017C50.978 42.326 51.6281 44.6466 50.7898 46.7834C49.9514 48.9202 47.942 50.0652 46.2991 49.3396C44.6563 48.614 44.0087 46.3001 44.8508 44.1619C45.6929 42.0238 47.6973 40.8815 49.3377 41.6057V41.6017ZM37.6969 39.5142C39.1528 39.6673 40.187 41.3328 40.0088 43.23C39.8305 45.1272 38.5027 46.5357 37.0456 46.3813C35.5884 46.2269 34.5543 44.5627 34.7337 42.6668C34.9132 40.771 36.2373 39.3571 37.6969 39.5142Z"
          fill="url(#paint2_linear_730_2250)"
        />
        <g filter="url(#filter2_f_730_2250)">
          <path
            d="M47.8504 60.72C56.7808 60.72 64.0204 53.1357 64.0204 43.78C64.0204 34.4243 56.7808 26.84 47.8504 26.84C38.9199 26.84 31.6804 34.4243 31.6804 43.78C31.6804 53.1357 38.9199 60.72 47.8504 60.72Z"
            fill="url(#paint3_radial_730_2250)"
            fillOpacity="0.5"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i_730_2250"
          x="61.0956"
          y="9.89594"
          width="26.1535"
          height="14.8439"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2.19909" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.357955 0 0 0 0 0.396477 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_730_2250"
          />
        </filter>
        <filter
          id="filter1_i_730_2250"
          x="53.4067"
          y="9.75963"
          width="19.7291"
          height="22.9701"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-4.39819" />
          <feGaussianBlur stdDeviation="2.19909" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_730_2250"
          />
        </filter>
        <filter
          id="filter2_f_730_2250"
          x="20.6849"
          y="15.8445"
          width="54.331"
          height="55.8709"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="5.49773"
            result="effect1_foregroundBlur_730_2250"
          />
        </filter>
        <linearGradient
          id="paint0_linear_730_2250"
          x1="29.3703"
          y1="30.69"
          x2="50.1603"
          y2="53.02"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#686C88" />
          <stop offset="1" stopColor="#686C88" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_730_2250"
          x1="55.904"
          y1="34.65"
          x2="55.904"
          y2="77.0785"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#704F4F" />
          <stop offset="1" stopColor="#704F4F" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_730_2250"
          x1="44.0003"
          y1="33"
          x2="44.0003"
          y2="60.72"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#C9C9C9" />
        </linearGradient>
        <radialGradient
          id="paint3_radial_730_2250"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(47.8504 43.78) rotate(90) scale(16.94 16.17)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
      </defs>
    </motion.svg>
  ) : showAllSquares ? (
    <motion.svg
      initial={{ opacity: 0 }}
      animate={{ opacity: 0.3, transition: { duration: 0.5 } }}
      width={size}
      height={size}
      viewBox="0 0 99 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="99" height="99" rx="12" fill="#1C1C29" />
      <path
        d="M51.4473 73.7229C51.4473 73.5428 51.5442 73.3765 51.701 73.2878L68.4909 63.7841C68.6463 63.6961 68.8368 63.6977 68.9907 63.7882L76.3168 68.0977C76.6485 68.2928 76.6446 68.7738 76.3098 68.9636L52.1939 82.6345C51.8606 82.8234 51.4473 82.5826 51.4473 82.1995V73.7229Z"
        fill="url(#paint0_linear_727_6974)"
      />
      <path
        d="M18 35.2945C18 34.9114 18.413 34.6707 18.7463 34.8593L26.3937 39.188C26.5504 39.2768 26.6474 39.443 26.6474 39.6231V59.5979C26.6474 59.7781 26.5504 59.9443 26.3937 60.0331L18.7463 64.3618C18.413 64.5504 18 64.3096 18 63.9266V35.2945Z"
        fill="url(#paint1_linear_727_6974)"
      />
      <path
        d="M51.7014 25.7698C51.5444 25.6811 51.4473 25.5148 51.4473 25.3345V16.8576C51.4473 16.4746 51.8603 16.2338 52.1936 16.4224L76.6353 30.2574C76.9704 30.447 76.9744 30.9283 76.6425 31.1235L69.3168 35.4328C69.163 35.5232 68.9727 35.5249 68.8173 35.4372L51.7014 25.7698Z"
        fill="url(#paint2_linear_727_6974)"
      />
      <path
        d="M21.7021 30.9563C21.3647 30.7645 21.365 30.2781 21.7027 30.0867L45.8057 16.4232C46.139 16.2343 46.5523 16.475 46.5523 16.8582V25.3324C46.5523 25.5138 46.454 25.681 46.2954 25.7692L29.5034 35.1158C29.3507 35.2007 29.1649 35.1999 29.013 35.1135L21.7021 30.9563Z"
        fill="url(#paint3_linear_727_6974)"
      />
      <path
        d="M71.6004 59.7078C71.4471 59.6182 71.3528 59.4539 71.3528 59.2762V40.1125C71.3528 39.9323 71.4498 39.7661 71.6065 39.6774L79.2539 35.3487C79.5872 35.16 80.0002 35.4008 80.0002 35.7838V63.7492C80.0002 64.1354 79.5811 64.3758 79.2478 64.1808L71.6004 59.7078Z"
        fill="url(#paint4_linear_727_6974)"
      />
      <path
        d="M21.8632 69.1306C21.526 68.9403 21.5236 68.4555 21.859 68.262L29.3363 63.9482C29.4898 63.8596 29.6787 63.8589 29.8328 63.9464L46.2991 73.2878C46.4557 73.3767 46.5524 73.5428 46.5524 73.7227V82.2017C46.5524 82.5844 46.1401 82.8252 45.8068 82.6372L21.8632 69.1306Z"
        fill="url(#paint5_linear_727_6974)"
      />
      <path
        d="M32.0418 59.1071L32.0418 40.1115L48.9996 30.444L65.9575 40.1115V59.1071L48.9996 68.6161L32.0418 59.1071Z"
        fill="url(#paint6_linear_727_6974)"
        stroke="url(#paint7_linear_727_6974)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_727_6974"
          x1="52.1561"
          y1="76.8851"
          x2="71.7119"
          y2="65.3777"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.365057" stopColor="#54BD23" />
          <stop offset="1" stopColor="#90F243" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_727_6974"
          x1="21.7523"
          y1="62.6166"
          x2="33.3064"
          y2="56.3261"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.375473" stopColor="#90F243" />
          <stop offset="1" stopColor="#54BD23" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_727_6974"
          x1="73.4736"
          y1="33.1315"
          x2="51.9266"
          y2="21.5192"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.365057" stopColor="#54BD23" />
          <stop offset="1" stopColor="#90F243" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_727_6974"
          x1="47.3684"
          y1="19.2632"
          x2="24.8938"
          y2="33.7035"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.365057" stopColor="#54BD23" />
          <stop offset="1" stopColor="#90F243" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_727_6974"
          x1="75.1051"
          y1="62.4999"
          x2="86.543"
          y2="56.1359"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.365057" stopColor="#54BD23" />
          <stop offset="1" stopColor="#90F243" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_727_6974"
          x1="42.7105"
          y1="80.6513"
          x2="21.5487"
          y2="69.272"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.432765" stopColor="#90F243" />
          <stop offset="1" stopColor="#54BD23" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_727_6974"
          x1="32.5079"
          y1="56.6886"
          x2="63.5279"
          y2="44.4032"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.365057" stopColor="#54BD23" />
          <stop offset="1" stopColor="#90F243" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_727_6974"
          x1="48.9996"
          y1="29.8684"
          x2="48.9996"
          y2="69.1894"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </motion.svg>
  ) : (
    <motion.svg
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      width={size}
      height={size}
      viewBox="0 0 99 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="99" height="99" rx="12" fill="#1C1C29" />
      <path
        d="M51.4472 73.7248C51.4472 73.5446 51.5441 73.3784 51.7009 73.2896L68.4907 63.7856C68.6461 63.6976 68.8366 63.6992 68.9906 63.7898L76.3166 68.0994C76.6483 68.2945 76.6445 68.7755 76.3097 68.9653L52.1938 82.6366C51.8605 82.8256 51.4472 82.5848 51.4472 82.2016V73.7248Z"
        fill="url(#paint0_linear_727_6974)"
      />
      <path
        d="M18 35.295C18 34.912 18.413 34.6712 18.7463 34.8599L26.3936 39.1887C26.5504 39.2775 26.6473 39.4437 26.6473 39.6239V59.5994C26.6473 59.7795 26.5504 59.9457 26.3936 60.0345L18.7463 64.3633C18.413 64.552 18 64.3112 18 63.9282V35.295Z"
        fill="url(#paint1_linear_727_6974)"
      />
      <path
        d="M51.7013 25.7701C51.5443 25.6814 51.4472 25.5151 51.4472 25.3348V16.8576C51.4472 16.4746 51.8602 16.2338 52.1935 16.4225L76.6352 30.2579C76.9702 30.4475 76.9742 30.9287 76.6424 31.1239L69.3166 35.4334C69.1628 35.5238 68.9725 35.5255 68.8172 35.4378L51.7013 25.7701Z"
        fill="url(#paint2_linear_727_6974)"
      />
      <path
        d="M21.702 30.9568C21.3646 30.765 21.365 30.2786 21.7026 30.0872L45.8056 16.4232C46.1389 16.2343 46.5522 16.4751 46.5522 16.8582V25.3327C46.5522 25.5141 46.4539 25.6813 46.2953 25.7696L29.5033 35.1164C29.3507 35.2013 29.1648 35.2005 29.013 35.1142L21.702 30.9568Z"
        fill="url(#paint3_linear_727_6974)"
      />
      <path
        d="M71.6002 59.7092C71.4469 59.6196 71.3527 59.4553 71.3527 59.2776V40.1133C71.3527 39.9331 71.4496 39.7669 71.6064 39.6781L79.2537 35.3493C79.587 35.1606 80 35.4014 80 35.7844V63.7508C80 64.1369 79.5809 64.3773 79.2476 64.1824L71.6002 59.7092Z"
        fill="url(#paint4_linear_727_6974)"
      />
      <path
        d="M21.8631 69.1323C21.5259 68.942 21.5236 68.4572 21.8589 68.2637L29.3362 63.9497C29.4897 63.8612 29.6787 63.8605 29.8328 63.948L46.299 73.2897C46.4556 73.3785 46.5523 73.5446 46.5523 73.7246V82.2039C46.5523 82.5866 46.14 82.8274 45.8067 82.6394L21.8631 69.1323Z"
        fill="url(#paint5_linear_727_6974)"
      />
      <path
        d="M32.0417 59.1084L32.0417 40.1123L48.9995 30.4444L65.9573 40.1123V59.1084L48.9995 68.6178L32.0417 59.1084Z"
        fill="url(#paint6_linear_727_6974)"
        stroke="url(#paint7_linear_727_6974)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_727_6974"
          x1="52.156"
          y1="76.8871"
          x2="71.7121"
          y2="65.3798"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.365057" stopColor="#54BD23" />
          <stop offset="1" stopColor="#90F243" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_727_6974"
          x1="21.7523"
          y1="62.6181"
          x2="33.3066"
          y2="56.3277"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.375473" stopColor="#90F243" />
          <stop offset="1" stopColor="#54BD23" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_727_6974"
          x1="73.4734"
          y1="33.1321"
          x2="51.9261"
          y2="21.52"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.365057" stopColor="#54BD23" />
          <stop offset="1" stopColor="#90F243" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_727_6974"
          x1="47.3683"
          y1="19.2633"
          x2="24.8933"
          y2="33.7034"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.365057" stopColor="#54BD23" />
          <stop offset="1" stopColor="#90F243" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_727_6974"
          x1="75.105"
          y1="62.5014"
          x2="86.543"
          y2="56.1375"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.365057" stopColor="#54BD23" />
          <stop offset="1" stopColor="#90F243" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_727_6974"
          x1="42.7104"
          y1="80.6534"
          x2="21.5484"
          y2="69.2744"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.432765" stopColor="#90F243" />
          <stop offset="1" stopColor="#54BD23" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_727_6974"
          x1="32.5079"
          y1="56.6899"
          x2="63.528"
          y2="44.4049"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.365057" stopColor="#54BD23" />
          <stop offset="1" stopColor="#90F243" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_727_6974"
          x1="48.9995"
          y1="29.8688"
          x2="48.9995"
          y2="69.1911"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </motion.svg>
  );
};
