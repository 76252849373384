import React, { useContext, useRef, useState } from "react";
import styles from "./DiceScreen.module.css";
import Currency from "../../../components/shared/svg/currency.svg";
import cross from "../images/x.svg";
import percent from "../images/percent.svg";
import flip from "../images/flip.svg";
import hr from "../images/hr.svg";
import DimensionsContext from "../../../context/dimensions/dimensions";
import { useHttp } from "../../../hooks/useHttps";
import { useSnackbar } from "../../../hooks/useSnackbar";
import DiceAnimations from "./DiceAnimations";
import { AuthContext } from "../../../context/auth/auth";
import useAuth from "../../../hooks/useAuth";
import Balance from "../../../components/UI/Balance/Balance";
import ReactGA from "react-ga4";
import scrollaudio from "../sounds/Scroll.mp3";
import diceloadingaudio from "../sounds/Diceloading.mp3";
import clickaudio from "../../../components/shared/sounds/Click.mp3";
import winaudio from "../../../components/shared/sounds/Win.mp3";

export const DiceScreen = () => {
  const betInputWrapperRef = useRef<HTMLInputElement>(null);
  const { x } = useContext(DimensionsContext);
  const { request } = useHttp();
  const { notify } = useSnackbar();
  const { getUser } = useAuth();
  const { balance } = useContext(AuthContext);

  const [wonTicket, setWonTicket] = useState<boolean>(false);
  const [processAnimStarted, setProcessAnimStarted] = useState<boolean>(false);
  const [startDiceGame, setStartDiceGame] = useState<boolean>(false);
  const [ticketData, setTicketData] = useState<number>(0);
  const [value, setValue] = useState<number>(50);
  const [bet, setBet] = useState<number>(100);
  const [flipStatus, setFlipStatus] = useState("under");

  const [soundDelay, setSoundDelay] = useState(false);

  const betOperation = (operation: string) => {
    operation === "multiple"
      ? setBet(bet * 2)
      : operation === "division"
      ? setBet((bet * 1) / 2)
      : setBet(balance);
  };

  const valueCalculating =
    flipStatus === "under" ? value.toFixed(2) : (100 - value).toFixed(2);

  const multiplierCalculating =
    flipStatus === "under" ? 97 / value : 97 / (100 - value);

  const startGame = async () => {
    try {
      setStartDiceGame(true);
      const sound = new Audio(clickaudio);
      sound.volume = 0.5;
      sound.currentTime = 0;
      sound.play();
      if (bet <= 0) {
        setStartDiceGame(false);
        notify("Bet value can't be 0", "error");
        return;
      }
      if (bet > balance) {
        setStartDiceGame(false);
        notify("You don't have enough balance", "error");
        return;
      }
      ReactGA.event({ category: "Make Bet", action: `make_bet_dice` });
      const data = await request("/api/games/dice/game", "POST", {
        betValue: bet,
        mode: flipStatus === "under" ? 0 : 1,
        rollValue: value,
      });
      if (data.statusCode === 400 || data.statusCode === 403) {
        setStartDiceGame(false);
        notify(data.msg, "error");
        return;
      }
      const sound2 = new Audio(diceloadingaudio);
      sound2.playbackRate = 2;
      sound2.volume = 0.5;
      sound2.currentTime = 0;
      sound2.play();
      setTicketData(0);
      setProcessAnimStarted(true);
      setTicketData(+(data.ticket * 100).toFixed(2));
      getUser();
      if (data.status === "won") {
        setTimeout(() => {
          const sound3 = new Audio(winaudio);
          sound3.volume = 0.2;
          sound3.currentTime = 0;
          sound3.play();
        }, 750);
        setWonTicket(true);
        // notify(`You won with ticket: ${data.ticket}`);
      } else {
        setWonTicket(false);
        // notify(`You lost with ticket: ${data.ticket}`, "error");
      }
      setTimeout(() => {
        setStartDiceGame(false);
      }, 850);
    } catch (e) {
      setStartDiceGame(false);
      notify("Failed to play game", "error");
    }
  };

  const multiplier: string = (
    Math.round(multiplierCalculating * 100) / 100
  ).toFixed(2);

  const changeFlipStatus = () => {
    flipStatus === "under" ? setFlipStatus("over") : setFlipStatus("under");
    setValue(100 - value);
  };

  const validateBet = (value: string) => {
    const regex = /^[0-9\b]+$/;
    if (value === "" || regex.test(value)) {
      setBet(Number(value));
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let sliderValue = Number(event.target.value);
    if (sliderValue < 2 || sliderValue > 98) {
      return;
    }
    if (!soundDelay) {
      const sound = new Audio(scrollaudio);
      sound.volume = 0.5;
      sound.currentTime = 0;
      sound.play();
      setSoundDelay(true);
      setTimeout(() => {
        setSoundDelay(false);
      }, 50);
    }
    // sound.pause();
    setValue(sliderValue);
  };

  return (
    <div className={styles.dicecontainer}>
      <div className={styles.wrapper}>
        <div className={styles.hrContainer}>
          <div className={styles.hrWrapper}>
            <h1>1</h1>
            <img src={hr} className={styles.hrImg} alt="" />
          </div>
          <div className={styles.hrWrapper}>
            <h1>25</h1>
            <img src={hr} className={styles.hrImg} alt="" />
          </div>
          <div className={styles.hrWrapper}>
            <h1>50</h1>
            <img src={hr} className={styles.hrImg} alt="" />
          </div>
          <div className={styles.hrWrapper}>
            <h1>75</h1>
            <img src={hr} className={styles.hrImg} alt="" />
          </div>
          <div className={styles.hrWrapper}>
            <h1>100</h1>
            <img src={hr} className={styles.hrImg} alt="" />
          </div>
        </div>
        <div ref={betInputWrapperRef} className="w-full relative">
          <div className={styles.animationWrapper}>
            <DiceAnimations
              ticketData={ticketData}
              wonTicket={wonTicket}
              wrapperWidth={betInputWrapperRef.current?.offsetWidth}
              processAnimStarted={processAnimStarted}
              setProcessAnimStarted={setProcessAnimStarted}
            />
          </div>
          <input
            type="range"
            min={0}
            max={100}
            step="1"
            value={value}
            disabled={startDiceGame}
            onChange={handleChange}
            className={styles.slider}
            style={{
              background: `linear-gradient(to right, ${
                flipStatus === "under" ? "#51D231" : "#C53111"
              } 0%, ${
                flipStatus === "under" ? "#51D231" : "#C53111"
              } ${value}%, ${
                flipStatus === "under" ? "#C53111" : "#51D231"
              } ${value}%, ${
                flipStatus === "under" ? "#C53111" : "#51D231"
              } 100%)`,
              marginTop: 42,
              height: 6,
              padding: 0,
            }}
          />
        </div>
        {x >= 600 ? (
          <>
            <button
              onClick={startGame}
              disabled={startDiceGame}
              className={styles.primaryBtn}
            >
              <p>Place Bet</p>
            </button>
            <div className={styles.betWindow}>
              <div className={styles.betWrapper}>
                <div style={{ width: "100%" }}>
                  <h3>Bet amount</h3>
                  <div
                    className={styles.betInputWrapper}
                    style={{ width: "100%" }}
                  >
                    <img
                      src={Currency}
                      style={{ width: 25, height: 25 }}
                      alt="currency"
                    />
                    <input
                      onChange={(e) => validateBet(e.target.value)}
                      value={bet}
                      type="text"
                      className={styles.inputAd}
                      // style={{ width: "100%" }}
                    />
                    <div className={styles.btnInputWrapper}>
                      <button
                        onClick={() => betOperation("division")}
                        className={styles.btnInput}
                      >
                        <p>1/2</p>
                      </button>
                      <button
                        onClick={() => betOperation("multiple")}
                        className={styles.btnInput}
                      >
                        <p>2x</p>
                      </button>
                      <button
                        onClick={() => betOperation("max")}
                        className={styles.btnInput}
                      >
                        <p>Max</p>
                      </button>
                    </div>
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <h3>Profit</h3>
                  <div
                    className={styles.betInputWrapper}
                    style={{ width: "100%" }}
                  >
                    <img
                      src={Currency}
                      style={{ width: 25, height: 25 }}
                      alt="currency"
                    />
                    <input
                      value={(Number(bet) * Number(multiplier)).toFixed(2)}
                      readOnly
                      type="text"
                      className={styles.inputAd}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 10 }} className={styles.betWrapper}>
                <div style={{ width: "100%" }}>
                  <h3>Payout</h3>
                  <div
                    className={styles.betInputWrapper}
                    style={{ width: "100%" }}
                  >
                    <img src={cross} style={{ width: 18, height: 15 }} alt="" />
                    <input
                      readOnly
                      value={multiplier}
                      type="text"
                      className={styles.inputAd}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <h3>Roll {flipStatus}</h3>
                  <div
                    className={styles.betInputWrapper}
                    style={{ width: "100%" }}
                  >
                    <input
                      readOnly
                      value={value}
                      type="text"
                      className={styles.inputAd}
                      style={{ width: "100%" }}
                    />
                    <div
                      className={styles.btnInputWrapper}
                      style={{ right: 16 }}
                    >
                      <button
                        onClick={changeFlipStatus}
                        className={styles.btnInput}
                      >
                        <img src={flip} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <h3>Chance</h3>
                  <div
                    className={styles.betInputWrapper}
                    style={{ width: "100%" }}
                  >
                    <img
                      src={percent}
                      style={{ width: 21, height: 17 }}
                      alt=""
                    />
                    <input
                      type="text"
                      className={styles.inputAd}
                      readOnly
                      value={valueCalculating}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{ border: "solid 2px #212432", borderRadius: "12px" }}
                >
                  <Balance />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className={styles.betWindow}>
            <div className={styles.betWrapper}>
              <div
                style={{ border: "solid 2px #212432", borderRadius: "12px" }}
              >
                <Balance />
              </div>
            </div>
            <div className={styles.betWrapper}>
              <div style={{ width: "100%" }}>
                <h3>Bet amount</h3>
                <div
                  className={styles.betInputWrapper}
                  style={{ width: "100%" }}
                >
                  <img
                    src={Currency}
                    style={{ width: 25, height: 25 }}
                    alt="currency"
                  />
                  <input
                    onChange={(e) => validateBet(e.target.value)}
                    value={bet}
                    type="text"
                    className={styles.inputAd}
                    style={{ width: "100%" }}
                  />
                  <div className={styles.btnInputWrapper}>
                    <button
                      onClick={() => betOperation("division")}
                      className={styles.btnInput}
                    >
                      <p>1/2</p>
                    </button>
                    <button
                      onClick={() => betOperation("multiple")}
                      className={styles.btnInput}
                    >
                      <p>2x</p>
                    </button>
                    <button
                      onClick={() => betOperation("max")}
                      className={styles.btnInput}
                    >
                      <p>Max</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.betWrapper}>
              <div style={{ width: "100%" }}>
                <h3>Profit</h3>
                <div
                  className={styles.betInputWrapper}
                  style={{ width: "100%" }}
                >
                  <img
                    src={Currency}
                    style={{ width: 25, height: 25 }}
                    alt="currency"
                  />
                  <input
                    value={(Number(bet) * Number(multiplier)).toFixed(2)}
                    readOnly
                    type="text"
                    className={styles.inputAd}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <h3>Payout</h3>
                <div
                  className={styles.betInputWrapper}
                  style={{ width: "100%" }}
                >
                  <img src={cross} style={{ width: 18, height: 15 }} alt="" />
                  <input
                    readOnly
                    value={multiplier}
                    type="text"
                    className={styles.inputAd}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
            <div className={styles.betWrapper}>
              <div style={{ width: "100%" }}>
                <h3>Roll {flipStatus}</h3>
                <div
                  className={styles.betInputWrapper}
                  style={{ width: "100%" }}
                >
                  <input
                    readOnly
                    value={value}
                    type="text"
                    className={styles.inputAd}
                    style={{ width: "100%" }}
                  />
                  <div className={styles.btnInputWrapper} style={{ right: 16 }}>
                    <button
                      onClick={changeFlipStatus}
                      className={styles.btnInput}
                    >
                      <img src={flip} alt="" />
                    </button>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <h3>Chance</h3>
                <div
                  className={styles.betInputWrapper}
                  style={{ width: "100%" }}
                >
                  <img src={percent} style={{ width: 21, height: 17 }} alt="" />
                  <input
                    type="text"
                    readOnly
                    value={valueCalculating}
                    className={styles.inputAd}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
            <div className={styles.betWrapper}>
              <button
                onClick={startGame}
                disabled={startDiceGame}
                className={styles.primaryBtn}
              >
                <p>Place Bet</p>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};