import { PlayField } from "./components/PlayField/PlayField";
import styles from "./MineSweeper.module.css";
import { useContext } from "react";
import DimensionsContext from "../../context/dimensions/dimensions";

export const MineSweeper = () => {
  const { x } = useContext(DimensionsContext);
  return (
    <div className={styles.wrapper}>
      <div
        style={{
          margin: "10px",
          width: x <= 960 ? "97%" : "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <PlayField />
      </div>
    </div>
  );
};
