import React from "react";

interface ISocketContext {
  socket: any;
  socketId: any;
  cleanUp: () => void;
  connect: () => void;
  depositCrash: (betValue: number, autoCashout: number) => void;
  cancelNextRoundBet: () => void;
  cashoutCrash: () => void;
  startAutoBet: (settings: any) => void;
  stopAutoBet: () => void;
  getBalance: () => void;
}

export const SocketContext = React.createContext<ISocketContext>({
  socket: null,
  socketId: null,
  cleanUp: () => null,
  connect: () => null,
  depositCrash: () => null,
  cancelNextRoundBet: () => null,
  cashoutCrash: () => null,
  startAutoBet: () => null,
  stopAutoBet: () => null,
  getBalance: () => null,
});
