import { useCallback, useContext, useEffect, useState } from "react";
import { Fields } from "./components/TowersField/TowersField";
import "./Towers.css";
import { getConfig } from "./config";
import { Mode } from "./types";
import { ReactComponent as TowersBottom } from "./images/towersbottom.svg";
import { useHttp } from "../../hooks/useHttps";
import { useSnackbar } from "../../hooks/useSnackbar";
import DimensionsContext from "../../context/dimensions/dimensions";
import GifWrapper from "../../components/UI/GifWrapper/GifWrapper";
import { towersWinGif, towersLoseGif } from "../../components/shared/gif";
import { BetWindow } from "../../components/UI/BetWindow/BetWindow";
import Balance from "../../components/UI/Balance/Balance";
import useAuth from "../../hooks/useAuth";
import { AuthContext } from "../../context/auth/auth";
import ReactGA from "react-ga4";
import clickaudio from "../../components/shared/sounds/Click.mp3";
import bombaudio from "../../components/shared/sounds/Bomb.mp3";
import crystalaudio from "../../components/shared/sounds/Crystal.mp3";
import winaudio from "../../components/shared/sounds/Win.mp3";
import _ from "lodash.debounce";

export function Towers() {
  const [bet, setBet] = useState<string>("100");
  const [actualBet, setActualBet] = useState<number>(Number(bet));

  const [flagField, setFlagField] = useState<boolean>(false);
  const [winAnimStarted, setWinAnimStarted] = useState<boolean>(false);
  const [lostAnimStarted, setLostAnimStarted] = useState<boolean>(false);
  const [isLose, setIsLose] = useState<boolean>(false);
  const [isWin, setIsWin] = useState<boolean>(false);
  const [mode, setMode] = useState<Mode>("easy");
  const [showAllSquares, setShowAllSquares] = useState<boolean>(false);

  const [revealedTowers, setRevealedTowers] = useState(null);
  const [revealed, setRevealed] = useState(null);
  const [round, setRound] = useState(0);
  const [multiplier, setMultiplier] = useState(1.0);

  const { request } = useHttp();
  const { notify } = useSnackbar();
  const { x } = useContext(DimensionsContext);
  const { getUser } = useAuth();
  const { balance } = useContext(AuthContext);

  const [towers, setTowers] = useState<number[][]>(
    Array(9).fill(getConfig("easy").starting)
  );

  const debouncedClick = useCallback(_(handleOnClickField, 150), [
    revealed,
    round,
  ]);

  useEffect(() => {
    setTowers(Array(9).fill(getConfig(mode).starting));
  }, [mode]);

  async function handleOnClickField(index: number) {
    try {
      const sound = new Audio(clickaudio);
      sound.volume = 0.5;
      sound.currentTime = 0;
      sound.play();
      const data = await request("/api/games/towers/game", "POST", {
        action: "reveal",
        index: index,
      });
      if (data.statusCode === 400 || data.statusCode === 403) {
        notify(data.msg, "error");
        return;
      }
      if (data.status === "lost") {
        const sound = new Audio(bombaudio);
        sound.volume = 0.2;
        sound.currentTime = 0;
        sound.play();
        setIsLose(true);
        setFlagField(false);
        setLostAnimStarted(true);
        notify("You lost the game, try again", "error");
        setTimeout(() => {
          setIsLose(false);
          setIsWin(false);
          setRevealed(null);
          setRound(0);
          setMultiplier(1.0);
          setShowAllSquares(true);
          setRevealedTowers(data.towers);
          // setTowersGame((prev) => ({ ...prev, towers: data.towers }));
        }, 1500);
      } else {
        const sound = new Audio(crystalaudio);
        sound.volume = 0.5;
        sound.currentTime = 0;
        sound.play();
      }
      setRevealed(data.revealed);
      setRound(data.round);
      setMultiplier(data.multiplier);
      if (data.round >= 9) {
        finishGame();
      }
    } catch (e: any) {}
  }

  const startGame = async () => {
    try {
      setShowAllSquares(false);
      const sound = new Audio(clickaudio);
      sound.volume = 0.5;
      sound.currentTime = 0;
      sound.play();
      if (actualBet <= 0) {
        notify("Bet value can't be 0", "error");
        return;
      }
      if (actualBet > balance) {
        notify("You don't have enough balance", "error");
        return;
      }
      ReactGA.event({ category: "Make Bet", action: `make_bet_towers` });
      const data = await request("/api/games/towers/start", "POST", {
        betValue: actualBet,
        mode: mode,
      });
      if (data.statusCode === 400 || data.statusCode === 403) {
        notify(data.msg, "error");
        return;
      }
      getUser();
      setFlagField(true);
      setRevealed(data.revealed);
      setRound(data.round);
      setMultiplier(data.multiplier);
    } catch (e: any) {}
  };

  const finishGame = async () => {
    try {
      const data = await request("/api/games/towers/game", "POST", {
        action: "cashout",
      });
      if (data.statusCode === 400 || data.statusCode === 403) {
        notify(data.msg, "error");
        return;
      }
      const sound = new Audio(winaudio);
      sound.volume = 0.2;
      sound.currentTime = 0;
      sound.play();
      getUser();
      setFlagField(false);
      setWinAnimStarted(true);
      notify("Successfully cashed out");
      setIsWin(true);
      setTimeout(() => {
        setIsLose(false);
        setIsWin(false);
        setRevealed(null);
        setRound(0);
        setMultiplier(1.0);
        setShowAllSquares(true);
        setRevealedTowers(data.towers);
        // setTowersGame((prev) => ({ ...prev, towers: data.towers }));
      }, 150);
    } catch (e: any) {
      notify("Failed to cash out", "error");
    }
  };

  const resetGame = () => {
    setShowAllSquares(false);
    setIsLose(false);
    setIsWin(false);
    setRevealedTowers(null);
    setRevealed(null);
    setRound(0);
    setMultiplier(1.0);
  };

  return (
    <div className="towers-container">
      <div className="towers-inner">
        {x > 1000 ? (
          <div className="towers">
            <div className="towers-left">
              <BetWindow
                game="towers"
                multiplier={multiplier}
                isEnabledSquare={flagField}
                bet={bet}
                actualBet={actualBet}
                setBet={setBet}
                setActualBet={setActualBet}
                startGame={startGame}
                cashOut={finishGame}
                options={mode}
                setOptions={setMode}
                isLose={isLose}
                isWin={isWin}
                resetGame={resetGame}
                showAllSquares={showAllSquares}
              />
              <Balance />
            </div>
            <div className="animation-wrapper">
              {winAnimStarted && (
                <GifWrapper
                  url={towersWinGif}
                  isAnimStarted={winAnimStarted}
                  setIsAnimStarted={setWinAnimStarted}
                  isShadow
                />
              )}
              {lostAnimStarted && (
                <GifWrapper
                  url={towersLoseGif}
                  isAnimStarted={lostAnimStarted}
                  setIsAnimStarted={setLostAnimStarted}
                  isShadow
                />
              )}
              <div className="towers-wrapper">
                <Fields
                  round={round}
                  handleOnClick={debouncedClick}
                  fields={towers}
                  revealed={revealed}
                  towers={revealedTowers}
                  flag={flagField}
                  mode={mode}
                  showAllSquares={showAllSquares}
                />
                <TowersBottom />
              </div>
            </div>
          </div>
        ) : (
          <div className="towers-mob">
            <div className="towers">
              <div className="animation-wrapper">
                {winAnimStarted && (
                  <GifWrapper
                    isShadow
                    isMobile
                    url={towersWinGif}
                    isAnimStarted={winAnimStarted}
                    setIsAnimStarted={setWinAnimStarted}
                  />
                )}
                {lostAnimStarted && (
                  <GifWrapper
                    isShadow
                    isMobile
                    url={towersLoseGif}
                    isAnimStarted={lostAnimStarted}
                    setIsAnimStarted={setLostAnimStarted}
                  />
                )}
                <div className="towers-wrapper">
                  <Fields
                    round={round}
                    handleOnClick={debouncedClick}
                    fields={towers}
                    revealed={revealed}
                    towers={revealedTowers}
                    flag={flagField}
                    mode={mode}
                    showAllSquares={showAllSquares}
                  />
                  <TowersBottom className="towers-bottom" />
                </div>
              </div>
            </div>
            <BetWindow
              game="towers"
              multiplier={multiplier}
              isEnabledSquare={flagField}
              bet={bet}
              actualBet={actualBet}
              setBet={setBet}
              setActualBet={setActualBet}
              startGame={startGame}
              cashOut={finishGame}
              options={mode}
              setOptions={setMode}
              isLose={isLose}
              isWin={isWin}
              resetGame={resetGame}
              showAllSquares={showAllSquares}
            />
            <Balance />
          </div>
        )}
      </div>
    </div>
  );
}
