import { useContext } from "react";
import { ReactComponent as Currency } from "../../shared/svg/currency.svg";
import "./Balance.css";
import { AuthContext } from "../../../context/auth/auth";

export default function Balance() {
  const { balance } = useContext(AuthContext);
  return (
    <div className="balance-box">
      <p>Your Balance:</p>
      <div>
        <Currency />
        <p>{balance.toFixed(2)}</p>
      </div>
    </div>
  );
}
