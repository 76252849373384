import { useEffect, useState } from "react";
import styles from "./CrashPlayers.module.css";
import { useRecoilState } from "recoil";
import { IActivePlayer, crashState } from "../../../../store/atoms/crashState";
import { crashPlayState } from "../../../../store/atoms/crashPlayerState";
import Currency from "../../../../components/shared/svg/currency.svg";
import Loading from "../../../../components/UI/Loading/Loading";

export default function CrashPlayers() {
  const [crashInfo, setCrashInfo] = useRecoilState(crashState);

  return (
    <div className={styles.crashplayers}>
      <div className={styles.crashplayers_header}>
        <p className={styles.crashplayers_text}>
          {crashInfo.activePlayers.length} Players
        </p>
      </div>
      {crashInfo.activePlayers.map((player: IActivePlayer, index: number) => (
        <div
          key={index}
          className={styles.crashplayers_row}
          style={index % 2 === 0 ? { background: "#2B2E40" } : {}}
        >
          <p
            className={styles.crashplayers_text}
            style={{ textTransform: "none" }}
          >
            {player.user}
          </p>
          <div className={styles.crashplayers_info}>
            {player.cashoutMultiplier !== 0 ? (
              <p
                className={styles.crashplayers_text}
                style={{ color: "#D3FF76" }}
              >
                {player.cashoutMultiplier.toFixed(2)}x
              </p>
            ) : (
              <></>
            )}
            <div className={styles.crashplayers_bet}>
              {player.isLose ? (
                <p
                  className={styles.crashplayers_text}
                  style={{ color: "#FF8166" }}
                >
                  {player.bet}
                </p>
              ) : player.cashoutMultiplier !== 0 ? (
                <p
                  className={styles.crashplayers_text}
                  style={{ color: "#D3FF76" }}
                >
                  {(player.bet * player.cashoutMultiplier).toFixed(2)}
                </p>
              ) : (
                <p className={styles.crashplayers_text}>{player.bet}</p>
              )}
              <img src={Currency} alt="currency" />
            </div>
          </div>
        </div>
      ))}
      {crashInfo.activePlayers.length === 0 ? <Loading /> : <></>}
    </div>
  );
}
