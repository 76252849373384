import styles from "./BetWindow.module.css";
import Currency from "../../shared/svg/currency.svg";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { AuthContext } from "../../../context/auth/auth";

type BetPropType = {
  options: any;
  setOptions: (param: any) => void;
  startGame: () => void;
  cashOut: () => void;
  bet: string;
  actualBet: number;
  setBet: Dispatch<SetStateAction<string>>;
  setActualBet: Dispatch<SetStateAction<number>>;
  isEnabledSquare: boolean;
  multiplier: number;
  isLose: boolean;
  isWin: boolean;
  resetGame: () => void;
  game: string;
  showAllSquares: boolean;
};

export const BetWindow = ({
  options,
  setOptions,
  startGame,
  cashOut,
  bet,
  actualBet,
  setBet,
  setActualBet,
  isEnabledSquare,
  multiplier,
  isLose,
  isWin,
  resetGame,
  game,
  showAllSquares,
}: BetPropType) => {
  const { balance } = useContext(AuthContext);
  const betOperation = (operation: string) => {
    if (operation === "multiple") {
      setBet(String(actualBet * 2));
      setActualBet(actualBet * 2);
    } else if (operation === "division") {
      setBet(String((actualBet * 1) / 2));
      setActualBet((actualBet * 1) / 2);
    } else {
      setBet(String(balance));
      setActualBet(balance);
    }
  };
  const validateBet = (value: string) => {
    const regex = /^(\d{1,10}|\d{1,10}\.|\d{0,10}\.\d{1,2})$/;
    if (value === "" || regex.test(value)) {
      setBet(value);
      setActualBet(Number(value));
    }
  };
  const [currIndex, setCurrIndex] = useState(0);
  return isEnabledSquare ? (
    <div className={styles.wrapperGame}>
      <h1 className={styles.multiplier}>x{multiplier.toFixed(2)}</h1>
      <div className={styles.balanceWrapper}>
        <div className={styles.balance}>
          <div className={styles.balanceLine}>
            <img src={Currency} alt="currency" />
            <h3>{actualBet.toFixed(2)}</h3>
          </div>
          <h4>Current cashout</h4>
        </div>
        <div className={styles.balance}>
          <div className={styles.balanceLine}>
            <img src={Currency} alt="currency" />
            <h3>{(actualBet * multiplier).toFixed(2)}</h3>
          </div>
          <h4>Next Tile</h4>
        </div>
      </div>
      <button
        disabled={multiplier === 1}
        onClick={cashOut}
        className={styles.primaryBtn}
      >
        <p>Cashout</p>
      </button>
    </div>
  ) : isLose ? (
    <div className={styles.wrapperGame}>
      {/* <h1 className={styles.gameOver}>Game Over</h1> */}
      <h1 className={styles.multiplierRed}>x{multiplier.toFixed(2)}</h1>
      <div className={styles.balanceWrapper}>
        <div className={styles.balance}>
          <div className={styles.balanceLine}>
            <img src={Currency} alt="currency" />
            <h3>{actualBet.toFixed(2)}</h3>
          </div>
          <h4>Current cashout</h4>
        </div>
        <div className={styles.balance}>
          <div className={styles.balanceLine}>
            <img src={Currency} alt="currency" />
            <h3>{(actualBet * multiplier).toFixed(2)}</h3>
          </div>
          <h4>Next Tile</h4>
        </div>
      </div>
      <button className={styles.primaryBtn} disabled={!isEnabledSquare}>
        <p>Cashout</p>
      </button>
    </div>
  ) : isWin ? (
    <div className={styles.wrapperGame}>
      {/* <h1 className={styles.gameOver}>Game Over</h1> */}
      <h1 className={styles.multiplier}>x{multiplier.toFixed(2)}</h1>
      <div className={styles.balanceWrapper}>
        <div className={styles.balance}>
          <div className={styles.balanceLine}>
            <img src={Currency} alt="currency" />
            <h3>{actualBet.toFixed(2)}</h3>
          </div>
          <h4>Current cashout</h4>
        </div>
        <div className={styles.balance}>
          <div className={styles.balanceLine}>
            <img src={Currency} alt="currency" />
            <h3>{(actualBet * multiplier).toFixed(2)}</h3>
          </div>
          <h4>Next Tile</h4>
        </div>
      </div>
      <button className={styles.primaryBtn} disabled={!isEnabledSquare}>
        <p>Cashout</p>
      </button>
    </div>
  ) : (
    <div className={styles.wrapper}>
      <h3>Bet Amount</h3>
      <div className={styles.inputWrapper}>
        <img src={Currency} alt="currency" />
        <input
          className={styles.inputAd}
          value={bet}
          onChange={(e) => validateBet(e.currentTarget.value)}
          type="text"
        />
        <div className={styles.btnInputWrapper}>
          <button
            onClick={() => betOperation("division")}
            className={styles.btnInput}
          >
            <p>1/2</p>
          </button>
          <button
            onClick={() => betOperation("multiple")}
            className={styles.btnInput}
          >
            <p>2x</p>
          </button>
          <button
            onClick={() => betOperation("max")}
            className={styles.btnInput}
          >
            <p>Max</p>
          </button>
        </div>
      </div>
      <h3 style={{ marginTop: 20 }}>
        {game === "mines" ? "Mines" : "Choose Mode"}
      </h3>
      {game === "mines" ? (
        <div className={styles.customSelect} tabIndex={1}>
          {Array.from({ length: 24 }, (_, i) => i + 1).map((numMines) => (
            <>
              <input
                onChange={() => setOptions(numMines)}
                checked={options === numMines}
                className={styles.selectOption}
                type="radio"
                id={`opt-${String(numMines)}`}
              />
              <label
                className={styles.optionLabel}
                htmlFor={`opt-${String(numMines)}`}
              >
                {numMines}
              </label>
            </>
          ))}
        </div>
      ) : (
        <div className={styles.btnWrapper}>
          {["easy", "medium", "hard", "nightmare"].map((item, index) => {
            return (
              <button
                key={index}
                className={
                  currIndex === index ? styles.rowBtnActive : styles.rowBtn
                }
                onClick={() => {
                  setCurrIndex(index);
                  setOptions(item);
                }}
              >
                {item}
              </button>
            );
          })}
        </div>
      )}
      <button
        style={{ marginTop: 30, width: "100%" }}
        onClick={startGame}
        className={styles.primaryBtn}
      >
        <p>Place Bet</p>
      </button>
    </div>
  );
};
