import "./Multipliers.css";
import { motion } from "framer-motion";

interface IMultipliers {
  multipliers: any[];
}

export default function Multipliers({ multipliers }: IMultipliers) {
  return (
    <div className="multipliers-wrapper">
      <div className="multipliers">
        {multipliers.map((Multiplier) => (
          <motion.div
            initial={{ y: -30 }}
            animate={{ y: 0, transition: { duration: 0.2 } }}
            key={Multiplier.id}
            className="multiplier"
          >
            <Multiplier.multiplier viewBox="10 1 26 30" />
          </motion.div>
        ))}
      </div>
    </div>
  );
}
