import styles from "./PlayField.module.css";
import leftTopPic from "../../images/leftTopPic.svg";
import leftBottomPic from "../../images/leftBottomPic.svg";
import cubGroup from "../../images/cubGroup.svg";
import cubGroupMin from "../../images/cubGroupMin.svg";
import { BetWindow } from "../../../../components/UI/BetWindow/BetWindow";
import { useContext, useLayoutEffect, useState } from "react";
import { Square } from "../Square/Square";
import DimensionsContext from "../../../../context/dimensions/dimensions";
import { useHttp } from "../../../../hooks/useHttps";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import GifWrapper from "../../../../components/UI/GifWrapper/GifWrapper";
import { towersLoseGif, towersWinGif } from "../../../../components/shared/gif";
import useAuth from "../../../../hooks/useAuth";
import { AuthContext } from "../../../../context/auth/auth";
import Balance from "../../../../components/UI/Balance/Balance";
import ReactGA from "react-ga4";
import clickaudio from "../../../../components/shared/sounds/Click.mp3";
import bombaudio from "../../../../components/shared/sounds/Bomb.mp3";
import crystalaudio from "../../../../components/shared/sounds/Crystal.mp3";
import winaudio from "../../../../components/shared/sounds/Win.mp3";
import _ from "lodash.debounce";

export const PlayField = () => {
  const { x } = useContext(DimensionsContext);
  const { request } = useHttp();
  const { notify } = useSnackbar();
  const { getUser } = useAuth();
  const { balance } = useContext(AuthContext);

  const [bet, setBet] = useState<string>("100");
  const [actualBet, setActualBet] = useState<number>(Number(bet));

  const [square, setSquare] = useState([
    { id: 1, isMine: false, isChecked: false },
  ]);
  const [flagField, setFlagField] = useState(false);
  const [multiplier, setMultiplier] = useState(1.0);
  const [isLose, setIsLose] = useState(false);
  const [isWin, setIsWin] = useState(false);
  const [numMines, setNumMines] = useState(3);
  const [showAllSquares, setShowAllSquares] = useState(false);

  const [winAnimStarted, setWinAnimStarted] = useState<boolean>(false);
  const [lostAnimStarted, setLostAnimStarted] = useState<boolean>(false);

  useLayoutEffect(() => {
    setSquare(
      Array.from({ length: 25 }, (_, i) => i + 1).map((item) => {
        return { id: item, isMine: false, isChecked: false };
      })
    );
  }, []);

  const generateMines = async () => {
    try {
      setShowAllSquares(false);
      setSquare(
        Array.from({ length: 25 }, (_, i) => i + 1).map((item) => {
          return { id: item, isMine: false, isChecked: false };
        })
      );
      const sound = new Audio(clickaudio);
      sound.volume = 0.5;
      sound.currentTime = 0;
      sound.play();
      ReactGA.event({ category: "Make Bet", action: `make_bet_plinko` });
      if (actualBet <= 0) {
        notify("Bet value can't be 0", "error");
        return;
      }
      if (actualBet > balance) {
        notify("You don't have enough balance", "error");
        return;
      }
      ReactGA.event({ category: "Make Bet", action: `make_bet_mines` });
      const data = await request("/api/games/mines/start", "POST", {
        betValue: actualBet,
        numMines,
      });
      if (data.statusCode === 400 || data.statusCode === 403) {
        notify(data.msg, "error");
        return;
      }
      getUser();
      setMultiplier(data.multiplier);
      setIsLose(false);
      setFlagField(true);
    } catch (e) {}
  };

  const cashOut = async () => {
    try {
      const data = await request("/api/games/mines/game", "POST", {
        action: "cashout",
      });
      if (data.statusCode === 400 || data.statusCode === 403) {
        notify(data.msg, "error");
        return;
      }
      const sound = new Audio(winaudio);
      sound.volume = 0.2;
      sound.currentTime = 0;
      sound.play();
      getUser();
      setWinAnimStarted(true);
      notify("Successfully cashed out");
      setFlagField(false);
      setIsWin(true);
      setTimeout(() => {
        setMultiplier(1.0);
        setIsLose(false);
        setIsWin(false);
        setShowAllSquares(true);
        const mineArr: number[] = [].concat(...data.mines);
        setSquare(
          mineArr.map((item: any, index: number) => ({
            id: index + 1,
            isChecked: item !== 0,
            isMine: item === -1,
          }))
        );
      }, 150);
    } catch (e) {
      notify("Failed to cash out", "error");
    }
  };

  const handleClick = async (id: number) => {
    try {
      const sound = new Audio(clickaudio);
      sound.volume = 0.5;
      sound.currentTime = 0;
      sound.play();
      const indexY = Math.floor((id - 1) / 5);
      const indexX = (id - 1) % 5;
      const data = await request("/api/games/mines/game", "POST", {
        action: "reveal",
        indexX,
        indexY,
      });
      if (data.statusCode === 400 || data.statusCode === 403) {
        notify(data.msg, "error");
        return;
      }
      if (data.status === "lost") {
        const sound = new Audio(bombaudio);
        sound.volume = 0.2;
        sound.currentTime = 0;
        sound.play();
        setLostAnimStarted(true);
        setIsLose(true);
        setFlagField(false);
        setTimeout(() => {
          setMultiplier(1.0);
          setIsLose(false);
          setIsWin(false);
          setShowAllSquares(true);
          const mineArr: number[] = [].concat(...data.mines);
          setSquare(
            mineArr.map((item: any, index: number) => ({
              id: index + 1,
              isChecked: item !== 0,
              isMine: item === -1,
            }))
          );
        }, 1500);
      } else {
        const sound = new Audio(crystalaudio);
        sound.volume = 0.5;
        sound.currentTime = 0;
        sound.play();
      }

      const mineArr: number[] = [].concat(...data.revealed);
      setMultiplier(data.multiplier);
      setSquare(
        mineArr.map((item: any, index: number) => ({
          id: index + 1,
          isChecked: item !== 0,
          isMine: item === -1,
        }))
      );
      if (data.round >= 25 - data.numMines) {
        cashOut();
      }
    } catch (e) {}
  };

  const resetGame = () => {
    setMultiplier(1.0);
    setIsLose(false);
    setIsWin(false);
    setShowAllSquares(false);
    setSquare(
      Array.from({ length: 25 }, (_, i) => i + 1).map((item) => {
        return { id: item, isMine: false, isChecked: false };
      })
    );
  };

  return x >= 960 ? (
    <div className={styles.wrapper}>
      <div className={styles.leftSide}>
        <Balance />
        <BetWindow
          game="mines"
          multiplier={multiplier}
          isEnabledSquare={flagField}
          bet={bet}
          actualBet={actualBet}
          setBet={setBet}
          setActualBet={setActualBet}
          startGame={generateMines}
          options={numMines}
          setOptions={setNumMines}
          cashOut={cashOut}
          isLose={isLose}
          isWin={isWin}
          resetGame={resetGame}
          showAllSquares={showAllSquares}
        />
        <img src={leftTopPic} className={styles.leftTopPic} alt="lefttop" />
        <img
          src={leftBottomPic}
          className={styles.leftBottomPic}
          alt="leftbottom"
        />
      </div>
      <div className="animation-wrapper">
        {winAnimStarted && (
          <GifWrapper
            url={towersWinGif}
            isAnimStarted={winAnimStarted}
            setIsAnimStarted={setWinAnimStarted}
            isShadow
          />
        )}
        {lostAnimStarted && (
          <GifWrapper
            url={towersLoseGif}
            isAnimStarted={lostAnimStarted}
            setIsAnimStarted={setLostAnimStarted}
            isShadow
          />
        )}
        <div className={styles.rightSide}>
          <div className={styles.cubWrapper}>
            {square.map((item) => {
              return (
                <Square
                  isChecked={item.isChecked}
                  onClick={() => handleClick(item.id)}
                  disabled={!flagField}
                  isMine={item.isMine}
                  position={item.id}
                  showAllSquares={showAllSquares}
                />
              );
            })}
          </div>
          <img src={cubGroup} className={styles.cubGroup} alt="block" />
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.wrapperMin}>
      <div className="animation-wrapper">
        {winAnimStarted && (
          <GifWrapper
            url={towersWinGif}
            isAnimStarted={winAnimStarted}
            setIsAnimStarted={setWinAnimStarted}
            isShadow
          />
        )}
        {lostAnimStarted && (
          <GifWrapper
            url={towersLoseGif}
            isAnimStarted={lostAnimStarted}
            setIsAnimStarted={setLostAnimStarted}
            isShadow
          />
        )}
        <div className={styles.rightSide}>
          <div className={styles.cubWrapper}>
            {square.map((item) => {
              return (
                <Square
                  isChecked={item.isChecked}
                  onClick={() => handleClick(item.id)}
                  disabled={!flagField}
                  isMine={item.isMine}
                  position={item.id}
                  showAllSquares={showAllSquares}
                />
              );
            })}
          </div>
          <img src={cubGroupMin} className={styles.cubGroupMin} alt="block" />
        </div>
      </div>
      <div className={styles.leftSideMin}>
        <Balance />
        <BetWindow
          game="mines"
          multiplier={multiplier}
          isEnabledSquare={flagField}
          bet={bet}
          actualBet={actualBet}
          setBet={setBet}
          setActualBet={setActualBet}
          startGame={generateMines}
          options={numMines}
          setOptions={setNumMines}
          cashOut={cashOut}
          isLose={isLose}
          isWin={isWin}
          resetGame={resetGame}
          showAllSquares={showAllSquares}
        />
      </div>
    </div>
  );
};
