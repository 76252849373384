import { useState, useEffect, useContext } from "react";
import io from "socket.io-client";
import { SocketContext } from "./socket";
import { useRecoilState } from "recoil";
import { IActivePlayer, crashState } from "../../store/atoms/crashState";
import { useSnackbar } from "../../hooks/useSnackbar";
import { crashPlayState } from "../../store/atoms/crashPlayerState";
import { AuthContext } from "../auth/auth";
import useAuth from "../../hooks/useAuth";
import crystalaudio from "../../components/shared/sounds/Crystal.mp3";
import bombaudio from "../../components/shared/sounds/Bomb.mp3";

export function SocketProvider({ children }: any) {
  const [socket, setSocket] = useState<any>(null);
  const [socketId, setSocketId] = useState(null);
  const [crash, setCrash] = useRecoilState(crashState);
  const [crashPlay, setCrashPlay] = useRecoilState(crashPlayState);
  const { notify } = useSnackbar();
  const { setBalance } = useContext(AuthContext);
  const { getUser } = useAuth();

  useEffect(() => {
    if (window.location.pathname.indexOf("/crash") > -1) {
      connect();
    }
    window.addEventListener("beforeunload", cleanUp);
    window.addEventListener("beforeclose", cleanUp);
    return () => cleanUp();
    // eslint-disable-next-line
  }, []);

  function cleanUp() {
    // window.socket && window.socket.emit("disconnect");
    window.socket && window.socket.close();
    setSocket(null);
    setSocketId(null);
  }

  const strToAB = (str: string) =>
    new Uint8Array(str.split("").map((c) => c.charCodeAt(0))).buffer;

  const ABToStr = (ab: ArrayBuffer) =>
    new Uint8Array(ab).reduce((p, c) => p + String.fromCharCode(c), "");

  async function connect() {
    const SOCKET_URL = window.location.host.includes(":")
      ? window.location.protocol === "http:"
        ? `ws://${window.location.hostname}:${process.env.REACT_APP_PORT}/`
        : `wss://${window.location.hostname}:${process.env.REACT_APP_PORT}`
      : window.location.protocol === "http:"
      ? `ws://${window.location.host}/`
      : `wss://${window.location.host}/`;

    const socket = io(SOCKET_URL, {
      transports: ["websocket"],
      upgrade: false,
    });
    registerCallbacks(socket);
    setSocket(socket);
    await getUser();
    socket.emit("auth", strToAB(localStorage.token ? localStorage.token : "demo"));
    socket.emit("requestCrashInfo");
    socket.emit("getBalance");
    // socket.emit("test_message", strToAB("test message"));
    window.socket = socket;
    return socket;
  }

  // CRASH FUNCTIONS
  function depositCrash(betValue: number, autoCashout: number) {
    window.socket.emit(
      "depositCrash",
      strToAB(JSON.stringify({ betValue, autoCashout }))
    );
  }

  function cancelNextRoundBet() {
    window.socket.emit("cancelNextRoundBet");
  }

  function cashoutCrash() {
    window.socket.emit("cashoutCrash");
  }

  function startAutoBet(settings: any) {
    window.socket.emit("startAutoBet", strToAB(JSON.stringify(settings)));
  }

  function stopAutoBet() {
    window.socket.emit("stopAutoBet");
  }

  function getBalance() {
    socket.emit("getBalance");
  }

  function registerCallbacks(socket: any) {
    // socket.on("test_message", (message: any) => {
    //   console.log(ABToStr(message));
    // });

    socket.on("active_players", (data: any) => {
      const activePlayers: IActivePlayer[] = JSON.parse(ABToStr(data));
      setCrash((prev) => ({ ...prev, activePlayers }));
    });

    socket.on("crash_info", (data: any) => {
      const crashInfo = JSON.parse(ABToStr(data));
      setCrash((prev) => ({ ...prev, ...crashInfo }));
    });

    socket.on("stop_multiplier_count", (data: any) => {
      const sound = new Audio(bombaudio);
      sound.volume = 0.2;
      sound.currentTime = 0;
      sound.play();
      const crashData = JSON.parse(ABToStr(data));
      setCrash((prev) => ({
        ...prev,
        crashed: true,
        game: false,
        crashValue: crashData.crashValue,
        nextRoundStart: crashData.nextRoundStart,
        timeElapsed: 0,
        crashTime: Date.now(),
        multiplier: crashData.crashValue,
      }));
    });

    socket.on("time_elapsed", (data: any) => {
      const timeElapsed = parseFloat(ABToStr(data));
      setCrash((prev) => ({ ...prev, timeElapsed }));
    });

    socket.on("start_multiplier_count", () => {
      setCrash((prev) => ({ ...prev, game: true }));
    });

    socket.on("crash_history", (data: any) => {
      const tail = JSON.parse(ABToStr(data));
      console.log(tail);
      setCrash((prev) => ({ ...prev, tail }));
    });

    socket.on("start_betting_phase", () => {
      setCrashPlay((prev) => ({
        ...prev,
        bettingNextRound: false,
        crashGameStarted: false,
        player: {
          isPlaying: false,
          bet: 0,
          cashoutMultiplier: 0,
          isLose: false,
        },
        cashedOut: false,
      }));
      setCrash((prev) => ({
        ...prev,
        betting: true,
        crashTime: 0,
        multiplier: 0,
        timeElapsed: 0,
      }));
    });

    socket.on("get_round_id_list", (data: any) => {
      const nextRound = parseInt(ABToStr(data));
      setCrash((prev) => ({ ...prev, round: nextRound }));
    });

    socket.on("balance", (balance: any) => {
      setBalance(parseFloat(ABToStr(balance)));
    });

    socket.on("success_deposit", (data: any) => {
      const bet = parseFloat(ABToStr(data));
      setCrashPlay((prev) => ({
        ...prev,
        player: { ...crashPlay.player, bet, isPlaying: true },
      }));
      notify("Successfully made a bet");
    });
    socket.on("failed_deposit", () => {
      notify("Failed to make a bet", "error");
    });
    socket.on("success_cashout", (data: any) => {
      const sound = new Audio(crystalaudio);
      sound.volume = 0.5;
      sound.currentTime = 0;
      sound.play();
      const cashoutMultiplier = parseFloat(ABToStr(data));
      setCrashPlay((prev) => ({
        ...prev,
        cashedOut: true,
        bettingNextRound: false,
        crashGameStarted: false,
        player: { ...crashPlay.player, cashoutMultiplier },
      }));
      notify("Successfully cashed out");
    });
    socket.on("failed_cashout", () => {
      notify("Failed to cash out", "error");
    });
    socket.on("success_start_autobet", () => {
      setCrashPlay((prev) => ({ ...prev, autoBet: true }));
      // notify("Successfully started autobet");
    });
    socket.on("success_stop_autobet", () => {
      setCrashPlay((prev) => ({ ...prev, autoBet: false }));
      // notify("Successfully stopped autobet");
    });
  }

  return (
    <SocketContext.Provider
      value={{
        depositCrash,
        cancelNextRoundBet,
        cashoutCrash,
        startAutoBet,
        stopAutoBet,
        connect,
        socket,
        socketId,
        cleanUp,
        getBalance,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}
