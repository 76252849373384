import styles from "./Square.module.css";
import { SquareSVG, OpenedSquare } from "./SquareSVG";
import bomb from "../../images/bomb.svg";
import { towersBombGif } from "../../../../components/shared/gif";

type CubPropType = {
  isChecked: boolean;
  isMine: boolean;
  position: number;
  onClick: () => void;
  disabled: boolean;
  showAllSquares: boolean;
};

export const Square = ({
  isChecked,
  isMine,
  position,
  onClick,
  disabled,
  showAllSquares,
}: CubPropType) => {
  return (
    <button disabled={disabled} onClick={onClick} className={styles.wrapper}>
      {showAllSquares ? (
        <OpenedSquare
          showAllSquares={showAllSquares}
          isMine={isMine}
          size={"100%"}
        />
      ) : isMine && isChecked ? (
        <>
          <img src={bomb} alt="bomb" />
          <div className={styles.bombgif}>
            <img src={towersBombGif} alt={"towersBombGif"} />
          </div>
        </>
      ) : !isMine && isChecked ? (
        <OpenedSquare
          showAllSquares={showAllSquares}
          isMine={isMine}
          size={"100%"}
        />
      ) : (
        <SquareSVG size={"100%"} position={position} isChecked={isChecked} />
      )}
    </button>
  );
};
