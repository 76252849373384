import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import AppWrapper from "./AppWrapper";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>
);

(() => {
  ReactGA.initialize("G-HW3CY6M7N8");
})();
