import AuthProvider from "./auth/AuthProvider";
import { SocketProvider } from "./socket/SocketProvider";
import DimensionsProvider from "./dimensions/DimensionsProvider";

export default function Providers({ children }: any) {
  return (
    <DimensionsProvider>
      <AuthProvider>
        <SocketProvider>
        {children}
        </SocketProvider>
      </AuthProvider>
    </DimensionsProvider>
  );
}
