import { Dispatch, SetStateAction, useEffect, useState } from "react";
import styles from "./BetWindow.module.css";
import Currency from "../../shared/svg/currency.svg";
import Inf from "../../shared/svg/infinity.svg";
import { useRecoilState } from "recoil";
import { crashState } from "../../../store/atoms/crashState";
import { crashPlayState } from "../../../store/atoms/crashPlayerState";

type BetPropType = {
  cashOut: () => void;
  makeBet: (nextRound: boolean) => void;
  cancelBet: () => void;
  bet: number;
  setBet: Dispatch<SetStateAction<number>>;
  autoCashout: number;
  setAutoCashout: Dispatch<SetStateAction<number>>;
  crashGameStarted: boolean;
  autoBetSettings: any;
  setAutoBetSettings: Dispatch<SetStateAction<any>>;
  autoBet: boolean;
  startAutoBet: () => void;
  stopAutoBet: () => void;
};

export default function CrashBet({
  setBet,
  cashOut,
  makeBet,
  cancelBet,
  bet,
  autoCashout,
  setAutoCashout,
  crashGameStarted,
  autoBetSettings,
  setAutoBetSettings,
  autoBet,
  startAutoBet,
  stopAutoBet,
}: BetPropType) {
  const [crashInfo, setCrashInfo] = useRecoilState(crashState);
  const [isCrashGame, setIsCrashGame] = useState(false);
  const [crashPlay, setCrashPlay] = useRecoilState(crashPlayState);

  const numOperation = (
    operation: string,
    value: number,
    setValue: (value: number) => void,
    cantBeZero = false
  ) => {
    if (operation === "multiple") {
      setValue(value * 2);
    } else if (operation === "division") {
      if (cantBeZero) {
        (value * 1) / 2 < 1 ? setValue(1.01) : setValue((value * 1) / 2);
      } else {
        setValue((value * 1) / 2);
      }
    }
  };

  useEffect(() => {
    if (crashInfo.game) {
      setIsCrashGame(true);
    }
    if (crashInfo.betting) {
      setIsCrashGame(false);
    }
  }, [crashInfo]);

  return crashPlay.mode === "auto" ? (
    <div style={{ height: "600px" }} className={styles.wrapper}>
      <div className={styles.modeButtons}>
        <button
          disabled={autoBet}
          className={styles.modeButton}
          onClick={() => setCrashPlay((prev) => ({ ...prev, mode: "manual" }))}
        >
          <p>Manual</p>
        </button>
        <button
          className={styles.modeButtonActive}
          onClick={() => setCrashPlay((prev) => ({ ...prev, mode: "auto" }))}
        >
          <p>Auto</p>
        </button>
      </div>
      <div className={styles.subWrapper}>
        <h3>Bet amount</h3>
        <div className={styles.inputWrapper}>
          <img src={Currency} alt="currency" />
          <input
            className={styles.inputAd}
            value={autoBetSettings.betValue}
            onChange={(e) =>
              isNaN(e.target.valueAsNumber)
                ? setAutoBetSettings((prev: any) => ({ ...prev, betValue: 0 }))
                : setAutoBetSettings((prev: any) => ({
                    ...prev,
                    betValue: Number(e.target.value.replace(/^0+/, "")),
                  }))
            }
            type="number"
            placeholder="0.00"
          />
          <div className={styles.btnInputWrapper}>
            <button
              onClick={() =>
                numOperation(
                  "division",
                  autoBetSettings.betValue,
                  (value: number) =>
                    setAutoBetSettings((prev: any) => ({
                      ...prev,
                      betValue: value,
                    }))
                )
              }
              className={styles.btnInput}
            >
              <p>1/2</p>
            </button>
            <button
              onClick={() =>
                numOperation(
                  "multiple",
                  autoBetSettings.betValue,
                  (value: number) =>
                    setAutoBetSettings((prev: any) => ({
                      ...prev,
                      betValue: value,
                    }))
                )
              }
              className={styles.btnInput}
            >
              <p>2x</p>
            </button>
          </div>
        </div>
        <h3 style={{ marginTop: "10px" }}>Auto Cashout</h3>
        <div className={styles.inputWrapper}>
          <input
            className={styles.inputAd}
            style={{ paddingLeft: 20 }}
            value={autoBetSettings.autoCashout}
            onChange={(e) =>
              isNaN(e.target.valueAsNumber)
                ? setAutoBetSettings((prev: any) => ({
                    ...prev,
                    autoCashout: 0,
                  }))
                : Number(e.target.value) < 1
                ? setAutoBetSettings((prev: any) => ({
                    ...prev,
                    autoCashout: 0,
                  }))
                : setAutoBetSettings((prev: any) => ({
                    ...prev,
                    autoCashout: Number(e.target.value.replace(/^0+/, "")),
                  }))
            }
            type="number"
            placeholder="0.00x"
          />
          <div className={styles.btnInputWrapper}>
            <button
              onClick={() =>
                numOperation(
                  "division",
                  autoBetSettings.autoCashout,
                  (value: number) =>
                    setAutoBetSettings((prev: any) => ({
                      ...prev,
                      autoCashout: value,
                    })),
                  true
                )
              }
              className={styles.btnInput}
            >
              <p>1/2</p>
            </button>
            <button
              onClick={() =>
                numOperation(
                  "multiple",
                  autoBetSettings.autoCashout,
                  (value: number) =>
                    setAutoBetSettings((prev: any) => ({
                      ...prev,
                      autoCashout: value,
                    }))
                )
              }
              className={styles.btnInput}
            >
              <p>2x</p>
            </button>
          </div>
        </div>
        <h3 style={{ marginTop: "10px" }}>Number of Bets</h3>
        <div className={styles.inputWrapper}>
          <input
            style={{ paddingLeft: 20 }}
            className={styles.inputAd}
            placeholder="0.00x"
            value={autoBetSettings.betNumber}
            onChange={(e) =>
              isNaN(Number(e.target.value))
                ? setAutoBetSettings((prev: any) => ({ ...prev, betNumber: 0 }))
                : setAutoBetSettings((prev: any) => ({
                    ...prev,
                    betNumber: Number(e.target.value.replace(/^0+/, "")),
                  }))
            }
            type="text"
          />
          <div className={styles.btnInputWrapper}>
            <button
              onClick={() =>
                setAutoBetSettings((prev: any) => ({
                  ...prev,
                  betNumber: Infinity,
                }))
              }
              className={styles.btnInput}
            >
              <img src={Inf} alt="infinity" />
            </button>
          </div>
        </div>
        <h3 style={{ marginTop: "10px" }}>Stop on Profit</h3>
        <div className={styles.inputWrapper}>
          <img src={Currency} alt="currency" />
          <input
            className={styles.inputAd}
            value={autoBetSettings.takeProfit}
            onChange={(e) =>
              isNaN(e.target.valueAsNumber)
                ? setAutoBetSettings((prev: any) => ({
                    ...prev,
                    takeProfit: 0,
                  }))
                : setAutoBetSettings((prev: any) => ({
                    ...prev,
                    takeProfit: Number(e.target.value.replace(/^0+/, "")),
                  }))
            }
            type="number"
            placeholder="0.00"
          />
          <div className={styles.btnInputWrapper}>
            <button
              onClick={() =>
                numOperation(
                  "division",
                  autoBetSettings.takeProfit,
                  (value: number) =>
                    setAutoBetSettings((prev: any) => ({
                      ...prev,
                      takeProfit: value,
                    }))
                )
              }
              className={styles.btnInput}
            >
              <p>1/2</p>
            </button>
            <button
              onClick={() =>
                numOperation(
                  "multiple",
                  autoBetSettings.takeProfit,
                  (value: number) =>
                    setAutoBetSettings((prev: any) => ({
                      ...prev,
                      takeProfit: value,
                    }))
                )
              }
              className={styles.btnInput}
            >
              <p>2x</p>
            </button>
          </div>
        </div>
        <h3 style={{ marginTop: "10px" }}>Stop on Loss</h3>
        <div style={{ marginBottom: "20px" }} className={styles.inputWrapper}>
          <img src={Currency} alt="currency" />
          <input
            className={styles.inputAd}
            value={autoBetSettings.stopLoss}
            onChange={(e) =>
              isNaN(e.target.valueAsNumber)
                ? setAutoBetSettings((prev: any) => ({ ...prev, stopLoss: 0 }))
                : setAutoBetSettings((prev: any) => ({
                    ...prev,
                    stopLoss: Number(e.target.value.replace(/^0+/, "")),
                  }))
            }
            type="number"
            placeholder="0.00"
          />
          <div className={styles.btnInputWrapper}>
            <button
              onClick={() =>
                numOperation(
                  "division",
                  autoBetSettings.stopLoss,
                  (value: number) =>
                    setAutoBetSettings((prev: any) => ({
                      ...prev,
                      stopLoss: value,
                    }))
                )
              }
              className={styles.btnInput}
            >
              <p>1/2</p>
            </button>
            <button
              onClick={() =>
                numOperation(
                  "multiple",
                  autoBetSettings.stopLoss,
                  (value: number) =>
                    setAutoBetSettings((prev: any) => ({
                      ...prev,
                      stopLoss: value,
                    }))
                )
              }
              className={styles.btnInput}
            >
              <p>2x</p>
            </button>
          </div>
        </div>
      </div>
      <button
        className={styles.primaryBtn}
        onClick={autoBet ? stopAutoBet : startAutoBet}
        style={{ marginTop: "0" }}
      >
        <p>{autoBet ? "Stop Autobet" : "Start Autobet"}</p>
      </button>
    </div>
  ) : crashPlay.player.isPlaying ? (
    <div className={styles.wrapperGame}>
      <div className={styles.modeButtons}>
        <button
          className={styles.modeButtonActive}
          onClick={() => setCrashPlay((prev) => ({ ...prev, mode: "manual" }))}
        >
          <p>Manual</p>
        </button>
        <button
          className={styles.modeButton}
          onClick={() => setCrashPlay((prev) => ({ ...prev, mode: "auto" }))}
        >
          <p>Auto</p>
        </button>
      </div>
      <h1 className={styles.multiplier}>
        {!crashPlay.cashedOut
          ? `x${crashInfo.multiplier}`
          : `x${crashPlay.player.cashoutMultiplier}`}
      </h1>
      <div className={styles.balanceWrapper}>
        <div className={styles.balance}>
          <div className={styles.balanceLine}>
            <img src={Currency} alt="currency" />
            <h3>
              {!crashPlay.cashedOut
                ? (bet * crashInfo.multiplier).toFixed(2)
                : (bet * crashPlay.player.cashoutMultiplier).toFixed(2)}
            </h3>
          </div>
          <h4>Current cashout</h4>
        </div>
      </div>
      <button
        disabled={crashInfo.crashed || !isCrashGame || crashPlay.cashedOut}
        className={styles.primaryBtn}
        onClick={cashOut}
      >
        <p>{!crashPlay.cashedOut ? "Cashout" : "Cashed Out"}</p>
      </button>
    </div>
  ) : crashPlay.player.isLose ? (
    <div className={styles.wrapperGame}>
      <div className={styles.modeButtons}>
        <button
          className={styles.modeButtonActive}
          onClick={() => setCrashPlay((prev) => ({ ...prev, mode: "manual" }))}
        >
          <p>Manual</p>
        </button>
        <button
          className={styles.modeButton}
          onClick={() => setCrashPlay((prev) => ({ ...prev, mode: "auto" }))}
        >
          <p>Auto</p>
        </button>
      </div>
      <h1 className={styles.gameOver}>Game Over</h1>
      <h1 className={styles.multiplierRed}>
        x{crashInfo.multiplier.toFixed(2)}
      </h1>
      <div className={styles.balanceWrapper}>
        <div className={styles.balance}>
          <div className={styles.balanceLine}>
            <img src={Currency} alt="currency" />
            <h3>{bet.toFixed(2)}</h3>
          </div>
          <h4>Current cashout</h4>
        </div>
      </div>
      <button className={styles.primaryBtn} disabled>
        <p>Cashout</p>
      </button>
    </div>
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.modeButtons}>
        <button
          className={styles.modeButtonActive}
          onClick={() => setCrashPlay((prev) => ({ ...prev, mode: "manual" }))}
        >
          <p>Manual</p>
        </button>
        <button
          className={styles.modeButton}
          onClick={() => setCrashPlay((prev) => ({ ...prev, mode: "auto" }))}
        >
          <p>Auto</p>
        </button>
      </div>
      <h3>Bet amount</h3>
      <div className={styles.inputWrapper}>
        <img src={Currency} alt="currency" />
        <input
          className={styles.inputAd}
          value={bet}
          onChange={(e) =>
            isNaN(e.target.valueAsNumber)
              ? setBet(0)
              : setBet(Number(e.target.value.replace(/^0+/, "")))
          }
          type="number"
          placeholder="0.00"
        />
        <div className={styles.btnInputWrapper}>
          <button
            onClick={() => numOperation("division", bet, setBet)}
            className={styles.btnInput}
          >
            <p>1/2</p>
          </button>
          <button
            onClick={() => numOperation("multiple", bet, setBet)}
            className={styles.btnInput}
          >
            <p>2x</p>
          </button>
        </div>
      </div>
      <h3 style={{ marginTop: "10px" }}>Auto Cashout</h3>
      <div className={styles.inputWrapper}>
        <input
          type="number"
          style={{ paddingLeft: 20 }}
          className={styles.inputAd}
          placeholder="0.00x"
          value={autoCashout === 0 ? "" : autoCashout}
          onChange={(e) =>
            isNaN(Number(e.target.value))
              ? setAutoCashout(0)
              : Number(e.target.value) < 1
              ? setAutoCashout(0)
              : setAutoCashout(e.target.valueAsNumber)
          }
        />
        <div className={styles.btnInputWrapper}>
          <button
            onClick={() =>
              numOperation("division", autoCashout, setAutoCashout, true)
            }
            className={styles.btnInput}
          >
            <p>1/2</p>
          </button>
          <button
            onClick={() =>
              numOperation("multiple", autoCashout, setAutoCashout)
            }
            className={styles.btnInput}
          >
            <p>2x</p>
          </button>
        </div>
      </div>
      {crashPlay.bettingNextRound ? (
        <button
          className={styles.primaryBtn}
          onClick={cancelBet}
        >
          <p>Cancel Bet</p>
        </button>
      ) : (
        <button
          className={styles.primaryBtn}
          disabled={crashGameStarted}
          onClick={() => makeBet(isCrashGame)}
        >
          <p>{isCrashGame ? "Bet on Next Round" : "Place Bet"}</p>
        </button>
      )}
    </div>
  );
}
