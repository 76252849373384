import { useContext, useState } from "react";
import Currency from "../../../../components/shared/svg/currency.svg";
// import { useAuthStore } from 'store/auth'

import { LinesTypes, Mode } from "../../types";
import styles from "./BetActions.module.css";
import { AuthContext } from "../../../../context/auth/auth";
import DimensionsContext from "../../../../context/dimensions/dimensions";

interface PlinkoBetActions {
  onRunBet: (betValue: number) => void;
  onChangeLines: (lines: LinesTypes) => void;
  onChangeRisk: (risk: Mode) => void;
}

export function BetActions({
  onRunBet,
  onChangeLines,
  onChangeRisk,
}: PlinkoBetActions) {
  const { x } = useContext(DimensionsContext);
  const {balance} = useContext(AuthContext)
  const [betValue, setBetValue] = useState(100);
  const linesOptions: number[] = [8, 10, 12, 14, 16];

  const [currIndex, setCurrIndex] = useState(0);
  const [currIndex2, setCurrIndex2] = useState(0);

  function handleChangeLines(value: number) {
    onChangeLines(Number(value) as LinesTypes);
  }

  function handleChangeRisk(value: string) {
    onChangeRisk(value as Mode);
  }

  const betOperation = (operation: string) => {
    operation === "multiple"
      ? setBetValue(betValue * 2)
      : operation === "division"
      ? setBetValue((betValue * 1) / 2)
      : setBetValue(balance);
  };

  const validateBet = (value: string) => {
    const regex = /^[0-9\b]+$/;
    if (value === "" || regex.test(value)) {
      setBetValue(Number(value));
    }
  };

  return (
    <div className={styles.wrapper}>
      <h3>Bet amount</h3>
      <div className={styles.inputWrapper}>
        <img src={Currency} alt="currency" />
        <input
          className={styles.inputAd}
          value={betValue}
          onChange={(e) => validateBet(e.currentTarget.value)}
          type="text"
        />
        <div className={styles.btnInputWrapper}>
          <button
            onClick={() => betOperation("division")}
            className={styles.btnInput}
          >
            <p>1/2</p>
          </button>
          <button
            onClick={() => betOperation("multiple")}
            className={styles.btnInput}
          >
            <p>2x</p>
          </button>
          <button
            onClick={() => betOperation("max")}
            className={styles.btnInput}
          >
            <p>Max</p>
          </button>
        </div>
      </div>
      {x <= 900 ? (
        <button
          style={{ marginTop: 30, width: "100%" }}
          onClick={() => onRunBet(betValue)}
          className={styles.primaryBtn}
        >
          <p>Place Bet</p>
        </button>
      ) : null}
      <h3 style={{ marginTop: 20 }}>Risk</h3>
      <div
        style={{ gridTemplateColumns: "repeat(3, 1fr)" }}
        className={styles.btnWrapper}
      >
        {["low", "medium", "high"].map((item, index) => {
          return (
            <button
              key={index}
              style={{ maxWidth: "82px" }}
              className={
                currIndex === index ? styles.rowBtnActive : styles.rowBtn
              }
              onClick={() => {
                setCurrIndex(index);
                handleChangeRisk(item);
              }}
            >
              {item}
            </button>
          );
        })}
      </div>
      <h3 style={{ marginTop: 20 }}>Rows</h3>
      <div className={styles.btnWrapper}>
        {linesOptions.map((item, index) => {
          return (
            <button
              key={index}
              className={
                currIndex2 === index ? styles.rowBtnActive : styles.rowBtn
              }
              onClick={() => {
                setCurrIndex2(index);
                handleChangeLines(item);
              }}
            >
              {item}
            </button>
          );
        })}
      </div>
      {x > 900 ? (
        <button
          style={{ marginTop: 30, width: "100%" }}
          onClick={() => onRunBet(betValue)}
          className={styles.primaryBtn}
        >
          <p>Place Bet</p>
        </button>
      ) : null}
    </div>
  );
}
