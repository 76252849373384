import {motion, useAnimationControls } from 'framer-motion';
import React, { FC, useEffect } from 'react';
import './GifWrapper.css'

export interface IGifWrapperProps {
    isMobile?: boolean
    isShadow?: boolean
    url: string
    isAnimStarted: boolean
    setIsAnimStarted: (value: boolean) => void
}

const GifWrapper:FC<IGifWrapperProps> = ({isMobile= false, isShadow= false, url, isAnimStarted, setIsAnimStarted}) => {
    const animationControls = useAnimationControls()
    const startAnimation = async () => {
        const anim = await animationControls.start({x: isMobile ? 20 : -100}, {duration: 0.4})

        if (anim) await animationControls.start({ x: isMobile ? 150 : 0 }, {delay: 2, duration: 0.3})
            .then(() => setIsAnimStarted(false))
    }

    useEffect(() => {
        if (isAnimStarted) {
            startAnimation()
        }
    }, [isAnimStarted])
    return (
        <motion.div
            initial={{ x: isMobile ? 150 : 0 }}
            animate={animationControls}
            className='gif-wrapper'
        >
            {isShadow && <div className='gif-shadow' />}
            <img src={url} alt='gif' />
        </motion.div>
    );
};

export default GifWrapper;

